import { HoverView, Pad, PadBox } from "np-platform-client/component/basics";
import { colorBlack, colorGreyBorder, colorGreyHover, colorNearBlack, colorRed, colorTextGrey, colorWhite } from "np-platform-client/component/color";
import { UtilityText } from "np-platform-client/component/text";
import { useState } from "react";
import { StyleSheet } from "react-native";
import { View } from "react-native-web";

/* Design: https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=6295-30850&node-type=frame&t=20KG9DOYaEhzgPAI-0

Changes compared to original FilterButton:
- selection is saved after press
- gray background on press
- white background when selected or unselected
- dark gray outline and black text when selected
- count is gray instead of red */

export function REPLACE_ZDF_FilterButton({emoji, label, text, count, selected, onPress}) {
    const s = FilterButtonStyle;

    const [pressed, setPressed] = useState(false);

    function toggleFilter() {
        onPress();
    }

    return (
        <HoverView
            style={[s.button, pressed && s.pressed, selected && s.selected]}
            pressedStyle={s.pressed}
            hoverStyle={s.hover}
            onPress={toggleFilter}
            setPressed={setPressed}
            testID={label ?? text}
        >
            {emoji && (
                <PadBox right={8}>
                    <UtilityText text={emoji} type="tiny" weight="medium" />
                </PadBox>
            )}
            <UtilityText label={label} text={text} type="tiny" color={colorBlack} weight="medium" />
            {count ? <Pad size={8} /> : null}
            <UtilityText text={count} type="tiny" color={colorTextGrey} />
        </HoverView>
    );
}
const FilterButtonStyle = StyleSheet.create({
    button: {
        height: 32,
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
        backgroundColor: colorWhite,
        borderColor: colorGreyBorder,
        borderWidth: 1,
        borderRadius: 100,
        paddingHorizontal: 12
    },
    hover: {
        backgroundColor: colorGreyHover,
    },
    pressed: {
        backgroundColor: colorGreyBorder,
    },
    selected: {
        borderColor: colorNearBlack,   
        backgroundColor: colorWhite,
    }
});


export function ThinTag({ emoji, icon, label, text, textColor = colorBlack, backgroundColor = colorWhite, formatParams }) {
    return (
        <View
            style={{
                justifyContent: "center",
                height: 20,
                borderRadius: 32,
                paddingVertical: 12,
                paddingHorizontal: 6,
                backgroundColor: backgroundColor,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            {emoji && (
                <>
                    <UtilityText text={emoji} type="tiny" strong />
                    {(icon || label || text) && <Pad size={6} />}
                </>
            )}
            {icon && (
                <>
                    {icon}
                    {(label || text) && <Pad size={6} />}
                </>
            )}
            <UtilityText label={label} text={text} type="tiny" color={textColor} formatParams={formatParams}/>
        </View>
    );
}