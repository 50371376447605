import { Add, ArrowUpRight, Chat, Subtract, Time, UserMultiple, ViewFilled } from "@carbon/icons-react";
import { Banner } from "np-platform-client/component/banner";
import { HorizBox, HoverView, Pad, PadBox, Separator } from "np-platform-client/component/basics";
import { CTAButton, IconButton, SubtleButton, TextButton } from "np-platform-client/component/button";
import { colorBlack, colorBlueBackground, colorDisabledBackground, colorGreyBorder, colorLightGreen, colorPink, colorPurpleBackground, colorTextBlue, colorTextGrey, colorWhite } from "np-platform-client/component/color";
import { SpacedArray } from "np-platform-client/component/demo";
import { Byline, FaceImage, LetterFace } from "np-platform-client/component/people";
import { Heading, Paragraph, TextField, TextFieldButton, UtilityText } from "np-platform-client/component/text";
import { useDatastore, useModulePublicData, usePersonaKey, usePersonaObject, useServerCallResult } from "np-platform-client/util/datastore";
import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { formatDate } from "np-platform-client/component/date";
import { RichText } from "np-platform-client/component/richtext";
import { ModerationFilters } from "./moddashboardqueue";
import { updateModerationSessionData } from "../../../structure/zdf/moddashboard";
import { useParentDatastore } from "../parentdatastore";
import { ModDashboardUserStatistics, useUserProfileModerationData } from "./moddashboarduserprofile";
import { ThinTag } from "../button";
import { Modal } from "np-platform-client/component/modal";
import { Catcher } from "np-platform-client/system/catcher";

export function ModDashboardCommentCard({ 
    comment,
    modAuthor,
    modTask,
    onPressView,
    viewLabel = "View",
    onClickCard,
    onExpand,
    onCollapse,
    highlighted,
    taskWatchState,
}) {
    const expanded = highlighted;
    const datastore = useDatastore()
    const linkedQuestion = useModulePublicData("question", [modTask.instanceKey]);
    
    const userId = modAuthor?.authorKey;
    const [isBlocked, setIsBlocked] = useState(false);
    let taskViewerName = "";
    if (taskWatchState) {
        taskViewerName = taskWatchState.name
    }
    useEffect(() => {
        const fetchIsBlocked = async () => {
            if (!userId) {
                return;
            }

            const isBlockedResult = await datastore.callServerAsync("moderationZdf", "getIsUserBlocked", {
                key: userId,
            });

            if (isBlockedResult === null || isBlockedResult === undefined) {
                setIsBlocked(false);
            } else {
                if (isBlockedResult !== isBlocked) {
                    setIsBlocked(isBlockedResult);
                }
            }
        };

        fetchIsBlocked();
    }, []);

    const onPressWholeCard = () => {
        onClickCard && onPressExpand(comment)
    }

    const onPressExpand = () => {
        onExpand && onExpand(comment)
    }

    const onPressCollapse = () => {
        onCollapse && onCollapse(comment)
    }

    const expandedSection = expanded && (
        <>
            <MessageToModerator message={modTask.appealText} />
            <CommentInformation comment={comment} modTask={modTask} />
            <NotesFromColleagues modTask={modTask} />

            {modTask.source === "premoderation" && <AIFlag modTask={modTask} />}
            {modTask.source === "report" && <UserReport modTask={modTask} />}
            {modTask.judgement === "reject" && <JudgementInformation modTask={modTask}/>}

            {modAuthor && <UserInformation modAuthor={modAuthor} />}
            <SubtleButton icon={Subtract} label={"Show less"} onPress={onPressCollapse} />
        </>
    );

    return (
        <HoverView
            style={[
                ModQueueCommentCardStyle.cardContainer,
                { borderWidth: 1, borderColor: expanded ? colorBlack : colorWhite, backgroundColor: colorWhite },
                { borderWidth: 1, borderColor: expanded ? colorBlack : colorWhite, backgroundColor: colorWhite },
            ]}
            onPress={!expanded && !highlighted && onPressWholeCard}
        >
            { (!modTask || !comment) && <View style={ModQueueCommentCardStyle.skeleton}></View>}
            <PadBox horiz={20} vert={20}>
                <View style={{ gap: 16 }}>
                    <View style={ModQueueCommentCardStyle.cardTopBar}>
                        <View style={ModQueueCommentCardStyle.commentStatusTags}>
                            <JudgementTag modTask={modTask} />
                            {modTask.receivedWarning && <WarningTag />}
                        </View>
                        {taskWatchState ? (
                            <ThinTag
                                icon={<ViewFilled color={colorTextBlue} />}
                                textColor={colorTextBlue}
                                backgroundColor={colorPurpleBackground}
                                label={taskWatchState.amIViewing ? "You are viewing" : "{viewerName} is viewing"}
                                formatParams={{viewerName: taskViewerName}}
                            />
                        ) : (
                            <ThinTag
                                icon={<ViewFilled color={colorTextBlue} />}
                                backgroundColor={colorPurpleBackground}
                            />
                        )}
                    </View>

                    {modAuthor && (
                        <BylineModerationCard
                            photoUrl={modAuthor.authorPhoto}
                            name={modAuthor.authorName}
                            userId={modAuthor.authorKey}
                            hue={modAuthor.authorHue}
                            size="tiny"
                            StatusTag={() =>
                                isBlocked && <ThinTag emoji={"🚫"} label="Blocked" backgroundColor={colorPink} />
                            }
                            SecondaryLineComponent={() =>
                                FormattedDateTime({ timestamp: modTask.objectTime ?? modTask.time, color: colorTextGrey })
                            }
                        />
                    )}
                    <Paragraph text={modTask?.text} numberOfLines={!expanded && 2} />
                    <HorizBox spread center>
                        <SpacedArray horiz pad={8}>
                            <UtilityText strong label={"Origin:"} />
                            <UtilityText
                                label={linkedQuestion ? linkedQuestion.question : "No origin available"}
                                color={colorTextGrey}
                            />
                        </SpacedArray>
                        <IconButton icon={ArrowUpRight} label={viewLabel} onPress={onPressView} />
                    </HorizBox>
                    {!expanded && <SubtleButton icon={Add} label={"Show more"} onPress={onPressExpand} />}
                    {expanded && expandedSection}
                </View>
            </PadBox>
        </HoverView>
    );
}

function MessageToModerator({ message }) {
    const style = StyleSheet.create({
        container: {
            paddingVertical: 16,
            gap: 12,
        },
        headingRow: {
            gap: 8,
            flexDirection: "row",
        },
    });

    if (message != null && message != undefined) {
        return (
            <View style={style.container}>
                <View style={style.headingRow}>
                    <Chat />
                    <UtilityText label={"Message to moderator:"} type="small" weight="medium" />
                </View>
                <Paragraph text={message} type="small" />
            </View>
        );
    }
}

function CommentDetailSection({ children }) {
    return <View style={ModQueueCommentCardStyle.commentDetailSection}>
        {children}
    </View>
}

function CommentInformation({ comment={}, modTask }) {
    return (
        <CommentDetailSection>
            <Heading label={"Comment information"} />
            <View style={[ModQueueCommentCardStyle.commentInformationContainer]}>
                <CommentInformationBlock label={"Posted"}>
                    <HorizBox>
                        <Time />
                        <Pad size={4} />
                        <UtilityText label={formatDate(modTask.objectTime ?? modTask.time)} />
                    </HorizBox>
                </CommentInformationBlock>
                {modTask.id && <CommentInformationBlock label={"Comment ID"}>
                    <UtilityText text={modTask.id} />
                </CommentInformationBlock>}
                <CommentInformationBlock label={"Status"}>
                    <JudgementTag modTask={modTask} />
                </CommentInformationBlock>
            </View>
        </CommentDetailSection>
    );
}

function CommentInformationBlock({ children, label }) {
    return <View style={ModQueueCommentCardStyle.commentInformationBlock}>
        <UtilityText strong label={label}></UtilityText>
        {children}
    </View>
}

function NoteFromColleague({ time, text, personaKey }) {
    const author = usePersonaObject(personaKey)
    if (author) {
        return (
            <View style={{ gap: 12, margin: 8 }}>
                <BylineModerationCard
                    name={author.name}
                    photo={author.photoUrl}
                    hue={author.hue}
                    SecondaryLineComponent={() => FormattedDateTime({ timestamp: time, color: colorTextGrey })}
                />
                <Paragraph text={text} type="small" />
            </View>
        );
    }

}

async function saveNote({ dataStore, from, text, modTaskKey }) {
    await dataStore.callServerAsync("moderationZdf", "setModeratorNote", {
        text: text,
        from: from,
        modTaskKey: modTaskKey
    })
}



function NotesFromColleagues({ modTask }) {
    const [modNotes, setModNotes] = useState([]);
    const dataStore = useDatastore()
    const [canWrite, setCanWrite] = useState(false)
    const [text, setText] = useState("")
    const personaKey = usePersonaKey()
    const modNoteCount = useModulePublicData("moderation", ["moderatorNotes", modTask.key, "amount"])

    useEffect(() => {
        async function getNotes() {
            const notes = await dataStore.callServerAsync("moderationZdf", "getModeratorNotes", {
                modTaskKey: modTask.key
            })
            setModNotes(Object.values(notes))
        }
        getNotes()
    }, [modNoteCount])

    const style = StyleSheet.create({
        container: {
            paddingVertical: 16,
            gap: 12
        },
        row: {
            gap: 8,
            flexDirection: "row"
        }
    })

    return <View style={style.container}>
        <View style={style.row}>
            <Heading label={"Internal Notes"} strong />
        </View>
        {modNotes.length > 0 ? modNotes.map((note, idx) => (<NoteFromColleague key={note.key} personaKey={note.from} time={note.time} text={note.text} />)) : null}
        {!canWrite ? <TextFieldButton placeholder='Add your notes...' onPress={() => { setCanWrite(true) }} /> : <View style={{ gap: 8 }}>
            <TextField autoFocus placeholder={"Add your notes..."} value={text} onChange={(newText) => setText(newText)} />
            <HorizBox right center>
                <TextButton label={"Cancel"} onPress={() => {
                    setText("")
                    setCanWrite(false)

                }} />
                <Pad size={28} />
                <CTAButton disabled={text.length <= 0} label={"Post"} type="large" onPress={() => {
                    saveNote({ dataStore: dataStore, from: personaKey, modTaskKey: modTask.key, text: text })
                    setCanWrite(false)
                    setText("")
                }
                } />
            </HorizBox>
        </View>}
    </View>
}

function AIFlag({ modTask }) {
    return (
        <CommentDetailSection>
            <Heading label={"AI flag"} />
            <ViolationContainer>
                    <RuleViolationList violations={modTask.violations} />
            </ViolationContainer>
        </CommentDetailSection>
    );
}

function ViolationContainer({ children }) {
    return <Banner>
        <View style={{ gap: 16 }}>
            {children}
        </View>
    </Banner>
}

const MultiReporterButtonStyle = StyleSheet.create(
    {
        count: { 
            width: 25,
            height: 25, 
            borderRadius: "50%", 
            backgroundColor: colorDisabledBackground, 
            justifyContent: "center", 
            alignItems: "center" }
    }
)

function MultiReporterButton({reports = []}){
    const reporters = reports.filter((report => report.reporter)).map(report=>report.reporter)
    const count = reporters?.length ?? 0;
    const [showReporters, setShowReporters] = useState(false)
    return <>
        <HorizBox center>
            <SubtleButton color={colorBlack} strong={true} icon={UserMultiple} label={"{count} " + (count === 1 ? "user" : "users")} formatParams={{ count: count.toString() }} onPress={() => { setShowReporters(true) }} />
        </HorizBox>
        {showReporters && <Modal onClose={() => { setShowReporters(false) }}>
            <PadBox horiz={20} vert={40}>
                <View style={{ gap: 32 }}>
                    <HorizBox center>
                        <Heading type="small" strong label={"Reported by users "} />
                        <Pad size={4} />
                        <View style={MultiReporterButtonStyle.count}>
                            <UtilityText type="small" text={reporters.length} center strong />
                        </View>
                    </HorizBox>
                    <View style={{ gap: 24 }}>
                        {reports.map(report => {
                            const reporter = report.reporter
                            return reporter ? <BylineModerationCard key={report.key} userId={reporter.key} name={reporter.name} hue={reporter.hue} photoUrl={reporter.photoUrl} SecondaryLineComponent={
                                () => FormattedDateTime({ timestamp: report.time, color: colorTextGrey })
                            } /> : null
                        })}
                    </View>
                </View>
            </PadBox>
        </Modal>}
    </>
}

function UserReport({modTask={}}){
    const reports = useServerCallResult("moderationZdf", "getReportsForModTask", {
        key: modTask.key
    })
    const reportObjects = reports ? Object.values(reports) : [];
    const reporters = reportObjects.filter((report => report.reporter)).map(report => report.reporter)

    return (
        <Catcher>
            <CommentDetailSection>
                <Heading label={"User report"} />
                <ViolationContainer>
                    <RuleViolationList violations={modTask.violations} />
                    {reporters.length > 0 && <><Separator />
                        <View>
                            <UtilityText label={"Reported by"} type="small" weight="medium" />
                            <Pad size={16} />
                            {reporters.length > 1 && <MultiReporterButton reports={reportObjects} />}
                            {reporters.length === 1 && (
                                <BylineModerationCard
                                    photoUrl={reporters[0].photoUrl}
                                    name={reporters[0].name}
                                    hue={reporters[0].hue}
                                    userId={reporters[0].key}
                                    size="tiny"
                                    SecondaryLineComponent={() =>
                                        FormattedDateTime({
                                            timestamp: modTask.judgementTime ?? modTask.time,
                                            color: colorTextGrey,
                                        })
                                    }
                                />
                            )}
                        </View></>}
                </ViolationContainer>
            </CommentDetailSection>
        </Catcher>
    );
}

function JudgementInformation({ modTask={} }) {

    return (
        <CommentDetailSection>
            <Heading label={"Human rejected "} />
            <ViolationContainer>
                <RuleViolationList violations={modTask.reasoning} />
                <Separator/>
                <View>
                    <UtilityText label={"Rejected by"} weight="medium" />
                    <Pad size={8} />
                    <BylineModerationCard
                        photoUrl={modTask.judgePhoto}
                        name={modTask.judgeName}
                        hue={modTask.judgeHue}
                        userId={modTask.judgeKey}
                        size="tiny"
                        SecondaryLineComponent={() =>
                            FormattedDateTime({ timestamp: modTask.judgementTime ?? modTask.time, color: colorTextGrey })
                        }
                    />
                </View>
            </ViolationContainer>
        </CommentDetailSection>
    );
}

function RuleViolationList({ violations }) {
    if (violations === "" || violations === undefined || violations === null) {
        violations = ["No information available."];
    }
    else {
        violations = JSON.parse(violations) ?? {};
        if (!Array.isArray(violations) && violations?.length === 0) {
            violations = ["No information available."]
        }
    }

    // Gets rid of duplicate violations
    violations = [...new Set(violations)];

    return (
        <>
            <Heading label={"📌 Community guideline violation"} />
            <View style={{ gap: 12 }}>
                {violations.map((violation, idx) => (
                    <RichText label={"• " + violation} key={"violation-" + idx} />
                ))}
            </View>
        </>
    );
}

function UserInformation({ modAuthor }) {
    const profileData = useUserProfileModerationData({userId: modAuthor.authorKey})

    const memberSince = () => (
        <HorizBox>
            <UtilityText label={"Member since "} color={colorTextGrey} />
            <UtilityText text={new Date(modAuthor.authorMemberSince).getFullYear().toString()} color={colorTextGrey} />
        </HorizBox>
    );

    return (
        <CommentDetailSection>
            <Heading label={"User information"} />
            <BylineModerationCard
                photoUrl={modAuthor.authorPhoto}
                name={modAuthor.authorName}
                userId={modAuthor.authorKey}
                hue={modAuthor.authorHue}
                size={"large"}
                SecondaryLineComponent={memberSince}
            />
            <ModDashboardUserStatistics userProfileData={profileData} />
        </CommentDetailSection>
    );
}

function JudgementTag({ modTask }) {
    let emoji = ModerationFilters.AwaitingDecision.emoji;
    let label = ModerationFilters.AwaitingDecision.text;

    let isReject = false;
    let isWaiting = true;

    if (modTask.judgement) {
        isReject = modTask?.judgement === 'reject';
        isWaiting = false;

        if (isReject) {
            emoji = ModerationFilters.Rejected.emoji;
            label = ModerationFilters.Rejected.text;
        } else {
            if (modTask.humanJudgement === true) {
                emoji = ModerationFilters.HumanApproved.emoji;
                label = ModerationFilters.HumanApproved.text;
            } else {
                emoji = ModerationFilters.AutomaticallyApproved.emoji;
                label = ModerationFilters.AutomaticallyApproved.text;
            }
        }
    }

    return (
        <ThinTag
            emoji={emoji}
            label={label}
            backgroundColor={isWaiting === true ? colorBlueBackground : isReject === true ? colorPink : colorLightGreen}
        />
    );
}

function WarningTag() {
    return (
        <ThinTag emoji="🚦" label="Warned" backgroundColor={colorPink}/>
    );
}

const ModQueueCommentCardStyle = StyleSheet.create({
    cardContainer: {
        flex: 1,
        minWidth: 0,
        borderRadius: 8,
        boxShadow: "0px 2px 10px 0px #0000001A",
        backgroundColor: colorWhite,
        position: "relative",
        overflow: "hidden",
    },
    cardTopBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    commentStatusTags: {
        display: "flex",
        flexDirection: "row",
        gap: 12,
    },
    commentDetailSection: {
        borderTopColor: colorGreyBorder,
        borderTopWidth: 1,
        paddingVertical: 16,
        gap: 24,
    },
    commentInformationContainer: {
        backgroundColor: colorWhite,
        flexDirection: "row",
        borderRadius: 11,
        padding: 16,
        gap: 24,
    },
    commentInformationBlock: {
        gap: 8,
    },
    eyeIcon: {
        flexDirection: "row",
        height: 20,
        paddingHorizontal: 6,
        gap: 6,
        backgroundColor: colorPurpleBackground,
        borderRadius: 32,
        alignItems: "center"
    },
    skeleton: {
        left: 0, right:0, bottom:0, top:0,
        position: "absolute",
        backgroundColor: colorWhite,
        zIndex: 10
    }
})

function BylineModerationCard({ photoUrl, hue, name, userId, size = "tiny", StatusTag, SecondaryLineComponent }) {
    // We have to use the parentDatastore in case the Moderation Card is shown within an embedded instance
    const parentDatatstore = useParentDatastore();

    function onProfile() {
        updateModerationSessionData({
            datastore: parentDatatstore, sessionData: {
                userId: userId
            }
        })
    }

    return (
        <View style={moderationBylineStyle.container}>
            <View>
                {photoUrl ?
                    <FaceImage type={size} photoUrl={photoUrl} /> :
                    <LetterFace type={size} name={name} hue={hue} />
            } 
            </View>
            <View style={[size === "tiny" ? moderationBylineStyle.row : moderationBylineStyle.column]}>
                <View style={moderationBylineStyle.statusTag}>
                    <TextButton type='small' text={name} strong onPress={userId && onProfile} />
                    {StatusTag && <StatusTag/>}
                </View>
                {SecondaryLineComponent && <SecondaryLineComponent />}
            </View>
        </View>
    );
}

const moderationBylineStyle = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 6
    },
    column: {
        flexDirection: 'column',
        gap: 2
    },
    statusTag: {
        flexDirection: 'row',
        gap: 6
    }
});

function FormattedDateTime({ timestamp, locale = null, color = colorBlack }) {
    const date = new Date(timestamp);
    const now = new Date();

    const clientLocale = locale || Intl.DateTimeFormat().resolvedOptions().locale;

    const isToday = date.toDateString() === now.toDateString();
    const isYesterday = date.toDateString() === new Date(now.setDate(now.getDate() - 1)).toDateString();

    const optionsTime = { hour: "2-digit", minute: "2-digit" };
    const formattedTime = date.toLocaleTimeString(clientLocale, optionsTime);

    let todayOrYesterdayLabel = "";
    let dateTimeLabel = "";

    if (isToday) {
        todayOrYesterdayLabel = "Today, ";
        dateTimeLabel = formattedTime;
    } else if (isYesterday) {
        todayOrYesterdayLabel = "Yesterday, ";
        dateTimeLabel = formattedTime;
    } else {
        const optionsDate = { day: "2-digit", month: "2-digit", year: "numeric" };
        const formattedDate = date.toLocaleDateString(clientLocale, optionsDate);
        dateTimeLabel = `${formattedDate} ${formattedTime}`;
    }

    return (
        <HorizBox>
            <UtilityText label={todayOrYesterdayLabel} color={color} />
            <UtilityText label={dateTimeLabel} color={color} />
        </HorizBox>
    );
}