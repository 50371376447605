export const AutoApproveFeature = {
    name: 'Auto Approved Comments in Queue',
    key: 'autoapprovecomments',
    config: {
        commentPostTriggers: [createAutoApprovedModTask],
    }
}

async function createAutoApprovedModTask({ datastore, commentKey, comment }) {
    if (comment.violates === false) {
        await datastore.callServerAsync('moderationZdf', 'autoApprove', {
            type: 'comment', 
            key: commentKey
        });
    }
}