import { DemoSection } from "np-platform-client/component/demo";
import { ModDashboardCommentCard } from "../../../component/zdf/moddashboard/moddashboardcommentcard";
import { Datastore } from "np-platform-client/util/datastore";
import { useState } from "react";
import { View } from "react-native";
import { HorizBox, Pad } from "np-platform-client/component/basics";
import { defaultPersonaList, personaListToMap } from "np-platform-client/util/testpersonas";
import { JudgementCard } from "../../../component/zdf/moddashboard/judgementcard";
import { ModDashboardQueueListItem } from "../../../component/zdf/moddashboard/moddashboardqueue";
import { modDashboardDemoData } from "./moddashboard.data";

export function ModDashboardCommentCardDemo() {

    const [selectedCard, setSelectedCard] = useState(null)
    const [notes, setNotes] = useState(modDashboardDemoData.modulePrivateModeratorNotes);

    const onPressView = () => {
        alert("View pressed. Should navigate to thread")
    }

    const onClickCard = (item) => {
        setSelectedCard(item.key)
    }

    const onExpandCard = (item) => {
        setSelectedCard(item.key)
    }

    const onCollapseCard = (item) => {
        setSelectedCard(null)
    }

    async function getModeratorNotesApi({ serverstore, modTaskKey }) {
        if (modTaskKey === modDashboardDemoData.modTaskQueues.thread1.c_t1_rejected.key) {
            return modDashboardDemoData.modulePrivateModeratorNotes.c_t1_rejected;
        } else {
            return {};
        }
    }

    async function setModeratorNoteApi({ datastore, from, text, modTaskKey }) {
        // TODO: Adding new internal notes doesn't work yet
        if (modTaskKey === modDashboardDemoData.modTaskQueues.thread1.c_t1_rejected.key) {
            const amountComments = modDashboardDemoData.modulePublicModeratorNotes.moderatorNotes.c_t1_rejected.amount;
            modDashboardDemoData.modulePublicModeratorNotes.moderatorNotes.c_t1_rejected.amount = amountComments + 1;
        }

        const key = Date.now() - 1000;
        const newData = {};
        newData[key] = { from: from, text: text, modTaskKey: modTaskKey, time: Date.now() };
        setNotes({ ...notes, ...newData });
    }

    const serverCalls = {
        moderationZdf: {
            ...modDashboardDemoData.serverCalls.default.moderationZdf,
            getModeratorNotes: getModeratorNotesApi,
            setModeratorNote: setModeratorNoteApi,
            watchTask: () => {},
            unwatchTask: () => {},
            getModItemAuthorsByIds: ({ authorIds }) => {
                const authors = modDashboardDemoData.demoModAuthors.reduce((acc, item) => {
                    acc[item.key] = item;
                    return acc;
                }, {});
                return authors;
            },
        },
    };

    return (
        <>
            <Datastore
                personaPreview={personaListToMap(defaultPersonaList)}
                isAdmin={true}
                roles={["Moderator"]}
                modulePublic={{
                    question: modDashboardDemoData.modulePublicQuestion,
                }}
                serverCall={serverCalls}
            >
                <DemoSection label="No rule violations">
                    <HorizBox>
                        <View style={{ width: "70%" }}>
                            <ModDashboardCommentCard
                                modAuthor={modDashboardDemoData.demoModAuthors.c_t1_approved_auto}
                                modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_approved_auto}
                                highlighted={
                                    selectedCard === modDashboardDemoData.commentsThread1.c_t1_approved_auto.key
                                }
                                comment={modDashboardDemoData.commentsThread1.c_t1_approved_auto}
                                onPressView={onPressView}
                                onClickCard={onClickCard}
                                onExpand={onExpandCard}
                                onCollapse={onCollapseCard}
                            />
                        </View>
                        {selectedCard === modDashboardDemoData.commentsThread1.c_t1_approved_auto.key && (
                            <View style={{ width: "30%", display: "flex", flexDirection: "row" }}>
                                <Pad size={16} />
                                <View style={{ display: "contents" }}>
                                    <JudgementCard
                                        modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_approved_auto}
                                    />
                                </View>
                            </View>
                        )}
                    </HorizBox>
                </DemoSection>

                <DemoSection label="AI flag">
                    <HorizBox>
                        <View style={{ width: "70%" }}>
                            <ModDashboardCommentCard
                                modAuthor={modDashboardDemoData.demoModAuthors.c_t1_awaiting_ai}
                                modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_ai}
                                highlighted={selectedCard === modDashboardDemoData.commentsThread1.c_t1_awaiting_ai.key}
                                comment={modDashboardDemoData.commentsThread1.c_t1_awaiting_ai}
                                onPressView={onPressView}
                                onClickCard={onClickCard}
                                onExpand={onExpandCard}
                                onCollapse={onCollapseCard}
                            />
                        </View>
                        {selectedCard === modDashboardDemoData.commentsThread1.c_t1_awaiting_ai.key && (
                            <View style={{ width: "30%", display: "flex", flexDirection: "row" }}>
                                <Pad size={16} />
                                <View style={{ display: "contents" }}>
                                    <JudgementCard
                                        modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_ai}
                                    />
                                </View>
                            </View>
                        )}
                    </HorizBox>
                </DemoSection>

                <DemoSection label="User report">
                    <HorizBox>
                        <View style={{ width: "70%" }}>
                            <ModDashboardCommentCard
                                modAuthor={modDashboardDemoData.demoModAuthors.c_t1_awaiting_report}
                                modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_report}
                                highlighted={
                                    selectedCard === modDashboardDemoData.commentsThread1.c_t1_awaiting_report.key
                                }
                                comment={modDashboardDemoData.commentsThread1.c_t1_awaiting_report}
                                onPressView={onPressView}
                                onClickCard={onClickCard}
                                onExpand={onExpandCard}
                                onCollapse={onCollapseCard}
                            />
                        </View>
                        {selectedCard === modDashboardDemoData.commentsThread1.c_t1_awaiting_report.key && (
                            <View style={{ width: "30%", display: "flex", flexDirection: "row" }}>
                                <Pad size={16} />
                                <View style={{ display: "contents" }}>
                                    <JudgementCard
                                        modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_report}
                                    />
                                </View>
                            </View>
                        )}
                    </HorizBox>
                </DemoSection>

                <DemoSection label="Approved by moderator">
                    <HorizBox>
                        <View style={{ width: "70%" }}>
                            <ModDashboardCommentCard
                                modAuthor={modDashboardDemoData.demoModAuthors.c_t1_approved_human}
                                modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_approved_human}
                                highlighted={
                                    selectedCard === modDashboardDemoData.commentsThread1.c_t1_approved_human.key
                                }
                                comment={modDashboardDemoData.commentsThread1.c_t1_approved_human}
                                onPressView={onPressView}
                                onClickCard={onClickCard}
                                onExpand={onExpandCard}
                                onCollapse={onCollapseCard}
                            />
                        </View>
                        {selectedCard === modDashboardDemoData.commentsThread1.c_t1_approved_human.key && (
                            <View style={{ width: "30%", display: "flex", flexDirection: "row" }}>
                                <Pad size={16} />
                                <View style={{ display: "contents" }}>
                                    <JudgementCard
                                        modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_approved_human}
                                    />
                                </View>
                            </View>
                        )}
                    </HorizBox>
                </DemoSection>
            </Datastore>

            <Datastore
                personaPreview={personaListToMap(defaultPersonaList)}
                isAdmin={true}
                roles={["Moderator"]}
                modulePublic={{
                    question: modDashboardDemoData.modulePublicQuestion,
                    moderation: modDashboardDemoData.modulePublicModeratorNotes,
                }}
                serverCall={serverCalls}
            >
                <DemoSection label="Rejected by moderator with internal notes">
                    <HorizBox>
                        <View style={{ width: "70%" }}>
                            <ModDashboardCommentCard
                                modAuthor={modDashboardDemoData.demoModAuthors.c_t1_rejected}
                                modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_rejected}
                                highlighted={selectedCard === modDashboardDemoData.commentsThread1.c_t1_rejected.key}
                                comment={modDashboardDemoData.commentsThread1.c_t1_rejected}
                                onPressView={onPressView}
                                onClickCard={onClickCard}
                                onExpand={onExpandCard}
                                onCollapse={onCollapseCard}
                            />
                        </View>
                        {selectedCard === modDashboardDemoData.commentsThread1.c_t1_rejected.key && (
                            <View style={{ width: "30%", display: "flex", flexDirection: "row" }}>
                                <Pad size={16} />
                                <View style={{ display: "contents" }}>
                                    <JudgementCard modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_rejected} />
                                </View>
                            </View>
                        )}
                    </HorizBox>
                </DemoSection>
            </Datastore>

            <Datastore
                serverCall={serverCalls}
                personaPreview={personaListToMap(defaultPersonaList)}
                modulePublic={{
                    question: modDashboardDemoData.modulePublicQuestion,
                    // TODO: Watch state doesn't have an effect yet
                    moderation: modDashboardDemoData.modulePublicTaskWatchState,
                }}
            >
                <DemoSection label="Comment cards with watch states">
                    <ModDashboardQueueListItem
                        comment={modDashboardDemoData.commentsThread1.c_t1_awaiting_ai}
                        modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_ai}
                        modAuthor={modDashboardDemoData.demoModAuthors.c_t1_awaiting_ai}
                    />
                    <ModDashboardQueueListItem
                        comment={modDashboardDemoData.commentsThread1.c_t1_awaiting_report}
                        modTask={modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_report}
                        modAuthor={modDashboardDemoData.demoModAuthors.c_t1_awaiting_report}
                    />
                </DemoSection>
            </Datastore>
        </>
    );
}