const { defaultPersonaList } = require("np-platform-client/util/testpersonas")

const demoAuthors = {
    a: { 
        ...defaultPersonaList[0], 
        memberSince: new Date(1000000000000),
    },
    b: { 
        ...defaultPersonaList[1], 
        memberSince: new Date(1000500000000)
    },
    c: { 
        ...defaultPersonaList[2], 
        memberSince: new Date(1001000000000)
    },
    d: { 
        ...defaultPersonaList[3], 
        memberSince: new Date(1001500000000)
    },
    e: { 
        ...defaultPersonaList[4], 
        memberSince: new Date(1002000000000)
    }
}

// TODO: We may want to define these values per user
const demoUserProfileData = {
    commentCount: 42,
    flagsGivenCount: 3,
    flagsReceivedCount: 2,
    rejectedCommentCount: 10,
    isBlocked: false
}

const demoModAuthors = {
    c_t1_approved_auto: {
        ...defaultPersonaList[2],
        from: defaultPersonaList[2].key,
        authorKey: defaultPersonaList[2].key,
        authorPhoto: defaultPersonaList[2].photoUrl,
        authorName: defaultPersonaList[2].name,
        authorMemberSince: new Date(1000000000000),
    },
    c_t1_awaiting_ai: {
        ...defaultPersonaList[0],
        authorKey: defaultPersonaList[0].key,
        authorPhoto: defaultPersonaList[0].photoUrl,
        authorName: defaultPersonaList[0].name,
        authorMemberSince: new Date(1000500000000),
    },
    c_t1_awaiting_report: {
        ...defaultPersonaList[3],
        authorKey: defaultPersonaList[3].key,
        authorPhoto: defaultPersonaList[3].photoUrl,
        authorName: defaultPersonaList[3].name,
        authorMemberSince: new Date(1001500000000),
        reporterKey: defaultPersonaList[4].key,
        reporterPhoto: defaultPersonaList[4].photoUrl,
        reporterName: defaultPersonaList[4].name,
    },
    c_t1_rejected: {
        ...defaultPersonaList[5],
        authorKey: defaultPersonaList[5].key,
        authorMemberSince: new Date(1001000000000),
        authorPhoto: defaultPersonaList[5].photoUrl,
        authorName: defaultPersonaList[5].name,
    },
    c_t1_approved_human: {
        ...defaultPersonaList[1],
        authorKey: defaultPersonaList[1].key,
        authorPhoto: defaultPersonaList[1].photoUrl,
        authorName: defaultPersonaList[1].name,
        authorMemberSince: new Date(1000500000000),
    }
}

const demoTimestamp = 1700000000000; // Consistent dates for demos
const modulePublicQuestion = {
    thread1: {
        key: "thread1",
        from: demoAuthors.a.key,
        question: "Is godzilla a serious problem?",
        time: demoTimestamp,
        commentCount: 100,
        countAwaitingDecision: 1,
        heated: true,
    },
    thread2: {
        key: "thread2",
        from: demoAuthors.b.key,
        question: "What's the best way to survive a zombie apocalypse?",
        time: demoTimestamp - 100000000,
        commentCount: 42
    },
    thread3: {
        key: "thread3",
        from: demoAuthors.c.key,
        question: "If aliens invade, who's negotiating?",
        time: demoTimestamp - 200000000,
        commentCount: 58,
        readonly: true,
    },
    thread4: {
        key: "thread4",
        from: demoAuthors.d.key,
        question: "Is pineapple on pizza actually good?",
        time: demoTimestamp - 2000000000,
        commentCount: 230
    }
}

const commentsThread1 = {
    c_t1_awaiting_ai: {
        key: "c_t1_awaiting_ai",
        from: demoAuthors.a.key,
        time: Date.now() - 10000000,
        instanceKey: modulePublicQuestion.thread1.key,
        text: "This comment was flagged by AI and is awaiting a moderator's decision.",
        violates: true,
    },
    c_t1_awaiting_report: {
        key: "c_t1_awaiting_report",
        from: demoAuthors.d.key,
        time: Date.now() - 9000000,
        instanceKey: modulePublicQuestion.thread1.key,
        text: "This comment was reported by another user.",
        violates: true, // TODO: Is this correct?
    },
    c_t1_approved_human: {
        key: "c_t1_approved_human",
        from: demoAuthors.b.key,
        time: Date.now() - 20000000,
        instanceKey: modulePublicQuestion.thread1.key,
        text: "This comment was approved by a human moderator.",
    },
    c_t1_approved_auto: {
        key: "c_t1_approved_auto",
        from: demoAuthors.c.key,
        time: Date.now() - 30000000,
        instanceKey: modulePublicQuestion.thread1.key,
        text: "This comment was automatically approved.",
    },
    c_t1_rejected: {
        key: "c_t1_rejected",
        from: demoAuthors.d.key,
        time: Date.now() - 40000000,
        instanceKey: modulePublicQuestion.thread1.key,
        text: "This comment was rejected by a human moderator.",
        violates: true,
    },
};

const backlinksThread1 = {
    article0: {
        title: "Godzilla on a rampage",
        key: "article0",
        image: "https://new-public-demo.web.app/photos/godzilla_new_york.jpeg"
    },
    article1: {
        title: "King Kong attacks Godzilla",
        key: "article1",
        image: "https://new-public-demo.web.app/photos/godzilla_new_york.jpeg"
    }
}

const modTasksThread1 = {
    c_t1_awaiting_ai: {
        ...commentsThread1.c_t1_awaiting_ai,
        from: null,
        type: "comment",
        instanceKey: modulePublicQuestion.thread1.key,
        time: Date.now() - 10000000,
        objectTime: Date.now() - 10000000,
        appealText: "I think my comment is harmless.",
        violations: "[\"No uncivil, hateful, or illegal content\"]",
        source: "premoderation",
    },
    c_t1_awaiting_report: {
        ...commentsThread1.c_t1_awaiting_report,
        from: null,
        type: "comment",
        instanceKey: modulePublicQuestion.thread1.key,
        time: Date.now() - 5000000,
        objectTime: Date.now() - 9000000,
        violations: "[\"No uncivil, hateful, or illegal content\"]",
        source: "report",
    },
    c_t1_approved_human: {
        ...commentsThread1.c_t1_approved_human,
        from: null,
        type: "comment",
        instanceKey: modulePublicQuestion.thread1.key,
        time: Date.now() - 20000000,
        objectTime: Date.now() - 20000000,
        violations: "[\"No uncivil, hateful, or illegal content\"]",
        source: "premoderation",
        judgement: "approve",
        humanJudgement: true,
        judgeKey: "NKV1ZAN0vcGbtrEF4I5a7...",
        judgeName: demoAuthors.a.name,
        judgePhoto: demoAuthors.a.photoUrl,
        judgementTime: Date.now() - 10000000,
    },
    c_t1_approved_auto: {
        ...commentsThread1.c_t1_approved_auto,
        from: null,
        type: "comment",
        instanceKey: modulePublicQuestion.thread1.key,
        time: Date.now() - 30000000,
        objectTime: Date.now() - 30000000,
        judgement: "approve",
        humanJudgement: false,
    },
    c_t1_rejected: {
        ...commentsThread1.c_t1_rejected,
        from: null,
        type: "comment",
        instanceKey: modulePublicQuestion.thread1.key,
        time: Date.now() - 40000000,
        objectTime: Date.now() - 40000000,
        appealText: "This is just my opinion!",
        judgement: "reject",
        humanJudgement: true,
        judgeKey: "NKV1ZAN0vcGbtrEF4I5a7...",
        judgeName: demoAuthors.a.name,
        judgePhoto: demoAuthors.a.photoUrl,
        judgementTime: Date.now() - 10000000,
        violations: "[\"No uncivil, hateful, or illegal content\"]",
        reasoning: "[\"No spam or commercial content\"]",
        source: "premoderation",
    }
};

const modTasksThread1SubQueues = {
    awaiting_decision: {
        c_t1_awaiting_ai: { 
            key: commentsThread1.c_t1_awaiting_ai.key, 
            time: commentsThread1.c_t1_awaiting_ai.time,      
            type: "comment"
        },
        c_t1_awaiting_report: { 
            key: commentsThread1.c_t1_awaiting_report.key, 
            time: commentsThread1.c_t1_awaiting_report.time,      
            type: "comment"
        }
    },
    human_approved: {
        c_t1_approved_human: { 
            key: commentsThread1.c_t1_approved_human.key, 
            time: commentsThread1.c_t1_approved_human.time,
            type: "comment"
        }
    },
    automatically_approved: {
        c_t1_approved_auto: { 
            key: commentsThread1.c_t1_approved_auto.key, 
            time: commentsThread1.c_t1_approved_auto.time,
            type: "comment"
        }
    },
    rejected: {
        c_t1_rejected: { 
            key: commentsThread1.c_t1_rejected.key, 
            time: commentsThread1.c_t1_rejected.time,
            type: "comment"
        }
    }
};

const modulePrivateModeratorNotes = {
    c_t1_rejected: {
        c_t1_rejected_n0: {
            from: demoAuthors.c.key,
            key: "c_t1_rejected_n0",
            modTaskKey: modTasksThread1.c_t1_rejected.key,
            time: Date.now() - 1000,
            text: "This could be spam"
        },
        c_t1_rejected_n1: {
            from: demoAuthors.d.key,
            key: "c_t1_rejected_n1",
            modTaskKey: modTasksThread1.c_t1_rejected.key,
            time: Date.now() - 500,
            text: "Yeah maybe, seems likely"
        }
    }
}

const modulePublicModeratorNotes = {
    moderatorNotes: {
        c_t1_rejected: {
            amount: 2
        }
    }
}

const taskWatchStates = {
    c_t1_awaiting_ai: {
        modID: demoAuthors.a,
        startTime: Date.now()
    },
    c_t1_awaiting_report: {
        modID: demoAuthors.b,
        startTime: Date.now(),
        name: demoAuthors.b.name
    },
}

const modulePublicTaskWatchState = {
    taskWatchState: taskWatchStates
}

const instanceData = {
    [modulePublicQuestion.thread1.key]: {
        globals: {
            name: modulePublicQuestion.thread1.question
        },
        collections: {
            "comment": Object.values(commentsThread1),
            "queue_filtered_awaiting_decision": Object.values(modTasksThread1SubQueues.awaiting_decision),
            "queue_filtered_human_approved": Object.values(modTasksThread1SubQueues.human_approved),
            "queue_filtered_auto_approved": Object.values(modTasksThread1SubQueues.automatically_approved),
            "queue_filtered_rejected": Object.values(modTasksThread1SubQueues.rejected),
            "persona": Object.values(demoAuthors),
            "backlink_article": Object.values(backlinksThread1)
        }
    }
};

function mockGetModItemAuthors() {
    // return demoAuthors
    return demoModAuthors;
}

function mockGetRemoteObjectFromDatabase({ collectionType, objectKey, instanceKey }) {
    return instanceData[instanceKey].collections[collectionType].find((c) => c.key === objectKey);
}

function mockGetUserProfileData(){
    return demoUserProfileData
}

function mockGetIsUserBlocked() {
    return demoUserProfileData.isBlocked;
}


const threadQueueCounts = {
    [modulePublicQuestion.thread1.key]: { 
        awaiting_decision_count: Object.values(modTasksThread1SubQueues.awaiting_decision).length 
    }
}

export const modDashboardDemoData = {
    modulePublicQuestion,
    threadQueueCounts,
    commentsThread1,
    demoAuthors,
    demoModAuthors,
    modulePrivateModeratorNotes,
    modulePublicModeratorNotes,
    modulePublicTaskWatchState,
    instanceData: instanceData,
    modTaskQueues: {
        thread1: modTasksThread1
    },
    modTaskSubQueues: {
        thread1: modTasksThread1SubQueues
    },
    serverCalls: {
        default: {
            moderation: {
                getModItemAuthors: mockGetModItemAuthors
            },
            moderationZdf: {
                getModItemAuthorsByIds: mockGetModItemAuthors,
                getRemoteObjectFromDatabase: mockGetRemoteObjectFromDatabase,
                getUserProfileData: mockGetUserProfileData,
                getIsUserBlocked: mockGetIsUserBlocked,
            }
        }
    }
}