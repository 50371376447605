import { useCollection, useDatastore, useModulePublicData } from "np-platform-client/util/datastore"
import { ModerationFilters } from "./moddashboardqueue"
import { useEffect, useState } from "react"

function useQueueCountGlobal() {
    const countAwaiting = useModulePublicData("moderation", ["count_queue_filtered", ModerationFilters.AwaitingDecision.id])
    const countHumanApproved = useModulePublicData("moderation", ["count_queue_filtered", ModerationFilters.HumanApproved.id])
    const countAutomaticallyApproved = useModulePublicData("moderation", ["count_queue_filtered", ModerationFilters.AutomaticallyApproved.id])
    const countRejected = useModulePublicData("moderation", ["count_queue_filtered", ModerationFilters.Rejected.id])
    return {
        [ModerationFilters.AwaitingDecision.id]: countAwaiting ?? 0,
        [ModerationFilters.AutomaticallyApproved.id]: countAutomaticallyApproved ?? 0,
        [ModerationFilters.HumanApproved.id]: countHumanApproved ?? 0,
        [ModerationFilters.Rejected.id]: countRejected ?? 0
    }
}

function useQueueCountInstance() {
    const queueAwaiting = useCollection('queue_filtered_awaiting_decision');
    const queueHumanApproved = useCollection('queue_filtered_human_approved');
    const queueRejected = useCollection('queue_filtered_rejected');
    const queueAutoApproved = useCollection('queue_filtered_auto_approved');

    return {
        [ModerationFilters.AwaitingDecision.id]: queueAwaiting.filter((task => task.time)).length,
        [ModerationFilters.AutomaticallyApproved.id]: queueAutoApproved.filter((task => task.time)).length,
        [ModerationFilters.HumanApproved.id]: queueHumanApproved.filter((task => task.time)).length,
        [ModerationFilters.Rejected.id]: queueRejected.filter((task => task.time)).length
    }
}

export function useQueueCounts({ structureKey, instanceKey }) {
    if (structureKey && instanceKey) {
        if(structureKey === "profile") {
            return getUserQueueCount(instanceKey)
        }
        return useQueueCountInstance();
    } else {
        return useQueueCountGlobal();
    }
}

function getUserQueueCount(userKey) {
    const userQueue = useUserQueue({ userId: userKey });
    const userQueueArray = Object.values(userQueue || {});

    const queueAwaiting = userQueueArray.filter((v) => v.judgement === undefined);
    const queueRejected = userQueueArray.filter((v) => v.judgement === "reject");
    const queueHumanApproved = userQueueArray.filter((v) => v.judgement === "approve" && v.humanJudgement === true);
    const queueAutomaticallyApproved = userQueueArray.filter((v) => v.judgement === "approve" && v.humanJudgement === false);
    const queueWarned = userQueueArray.filter((v) => v.receivedWarning === true);

    return {
        [ModerationFilters.AwaitingDecision.id]: queueAwaiting.length,
        [ModerationFilters.AutomaticallyApproved.id]: queueAutomaticallyApproved.length,
        [ModerationFilters.HumanApproved.id]: queueHumanApproved.length,
        [ModerationFilters.Rejected.id]: queueRejected.length,
        [ModerationFilters.Warned.id]: queueWarned.length,
    };
}

function useUserQueue({ userId }) {
    const datastore = useDatastore();
    const timestampLastModerationAction = useModulePublicData("moderation", ["user", userId, "timeLastModerationAction"]);
    const [userQueue, setUserQueue] = useState([]);

    async function fetchUserQueue() {
        const result = await datastore.callServerAsync("moderationZdf", "getUserQueue", { key: userId });
        setUserQueue(Object.values(result));
    }

    // Fetches queue whenever new mod tasks come in or when mod tasks change status (e.g. from awaiting decision to rejected)
    useEffect(() => {
        fetchUserQueue();
    }, [timestampLastModerationAction]);
    return userQueue;
}


/**
 * General hook to retrieve all necessary queue data
 * @param filterOptions Array of `ModerationFilters`
 * @param sortingOrder "newest" or "oldest"
 * @param queueRetrivalMethod Hook to retrieve a subqueue based on filter option (currently all or instance based)
 */


export function useQueueData ({ 
    filterOptions = [], 
    sortingOrder = "oldest", 
    limit =  20,
    startTime = Date.now(), 
    queueRetrievalMethod = useFilteredQueueAll})  {

    // Final queue to be returned
    const [returnQueue, setReturnQueue] = useState([])
    
    // Count amount of more items to load (not the total count!)
    const [moreItemsToLoad, setMoreItemsToLoad] = useState(0)
    // Count of newly added items, which are available, but not returned
    const [newItemsToLoad, setNewItemsToLoad] = useState([])

    // Get all subQueues for each ModerationFilters
    const { queue: awaitingQueue, newItemsToLoad: newAwaiting } = queueRetrievalMethod({
        filterOption: ModerationFilters.AwaitingDecision,
        limit: limit,
        startTime: startTime,
        sortingOrder: sortingOrder,
    });
    const { queue: automaticallyApprovedQueue, newItemsToLoad: newAuto } = queueRetrievalMethod({
        filterOption: ModerationFilters.AutomaticallyApproved,
        limit: limit,
        startTime: startTime,
        sortingOrder: sortingOrder,
    });
    const { queue: humanApprovedQueue, newItemsToLoad: newHuman } = queueRetrievalMethod({
        filterOption: ModerationFilters.HumanApproved,
        limit: limit,
        startTime: startTime,
        sortingOrder: sortingOrder,
    });
    const { queue: rejectedQueue, newItemsToLoad: newRejected } = queueRetrievalMethod({
        filterOption: ModerationFilters.Rejected,
        limit: limit,
        startTime: startTime,
        sortingOrder: sortingOrder,
    });
    const { queue: warnedQueue, newItemsToLoad: newWarned } = queueRetrievalMethod({
        filterOption: ModerationFilters.Warned,
        limit: limit,
        startTime: startTime,
        sortingOrder: sortingOrder,
    });

    useEffect(() => {
        let combinedQueue = [];
        let newItems = [];
        // Assemble queues based on the filterOptions array
        if (filterOptions.length === 0 || filterOptions.includes(ModerationFilters.AwaitingDecision)) {
            combinedQueue = combinedQueue.concat(awaitingQueue);
            newItems = newItems.concat(newAwaiting);
        }
        if (filterOptions.length === 0 || filterOptions.includes(ModerationFilters.AutomaticallyApproved)) {
            combinedQueue = combinedQueue.concat(automaticallyApprovedQueue);
            newItems = newItems.concat(newAuto);
        }
        if (filterOptions.length === 0 || filterOptions.includes(ModerationFilters.HumanApproved)) {
            combinedQueue = combinedQueue.concat(humanApprovedQueue);
            newItems = newItems.concat(newHuman);
        }
        if (filterOptions.length === 0 || filterOptions.includes(ModerationFilters.Rejected)) {
            combinedQueue = combinedQueue.concat(rejectedQueue);
            newItems = newItems.concat(newRejected);
        }
        if (filterOptions.includes(ModerationFilters.Warned)) {
            combinedQueue = combinedQueue.concat(warnedQueue);
            newItems = newItems.concat(newWarned);
        }

        // No filter selected = show comments from all sub-queues
        // TODO: Reconsider if this is necessary. Showing everything at once might cause performance issues.
        if (filterOptions.length === 0) {
            combinedQueue = combinedQueue.concat(
                awaitingQueue,
                automaticallyApprovedQueue,
                humanApprovedQueue,
                rejectedQueue
            );
        }

        // Remove duplicate mod tasks from combined queue.
        // This is important because one comment can appear under multiple filters, e.g. rejected and warned.
        const queueStringified = combinedQueue.map((modTask) => JSON.stringify(modTask));
        const queueStringifiedWithoutDupes = queueStringified.filter(
            (stringifiedModTask, idx) => queueStringified.indexOf(stringifiedModTask) === idx
        );
        combinedQueue = queueStringifiedWithoutDupes.map((stringifiedModTask) => JSON.parse(stringifiedModTask));

        const filteredQueue = combinedQueue.sort((a, b) => {
            if (sortingOrder === "newest") {
                return b.time - a.time;
            } else {
                return a.time - b.time;
            }
        });

        setReturnQueue(filteredQueue.slice(0, limit));

        // Update the new and more items count
        setNewItemsToLoad(newItems);
        setMoreItemsToLoad(combinedQueue.length - limit);
    }, [awaitingQueue, automaticallyApprovedQueue, humanApprovedQueue, rejectedQueue, limit]);
    
    return {
        queue: returnQueue,
        moreItemsToLoad: moreItemsToLoad,
        newItemsToLoad: newItemsToLoad,
    }
};

/** Retrieves modTasks for all instances (from module public) */
export function useFilteredQueueAll({filterOption, limit= 20, startTime= Date.now(), sortingOrder= "oldest"}) {

    // Always load 1 more than the minimum amount to be able to see if there are more items to retrieve
    const [currentLimit, setLimit] = useState(limit+1);
    const [newItemsToLoad, setNewItemsToLoad] = useState([]);
    const [returnQueue, setReturnQueue] = useState([])

    const modObjs = useModulePublicData('moderation', ['queue_filtered', filterOption.id], {limit: currentLimit, oldest: sortingOrder==="oldest"});

    useEffect(()=>{

        //Filter the returned queu based on specified start time and end time
        const keys = Object.keys(modObjs || {});
        const queue = keys.map(key => ({ key, ...modObjs[key] })).sort((a, b) => b.time - a.time);

        const filteredQueue = queue.filter(item => sortingOrder==="oldest" ? item.time >= 0 : item.time <= startTime);
        const newItems = queue.filter(item => sortingOrder==="oldest" ? item.time <= 0 :  item.time >= startTime)

        // Case Filtered Queue becomes smaller, than the returned queue -> Means newer items have been added -> More items need to be loaded
        // We have to increase the limit and fetch more
        if(filteredQueue.length < returnQueue.length) {
            setLimit(currentLimit + limit)
        }
        setNewItemsToLoad(newItems)  
        setReturnQueue(filteredQueue);    
    }, [modObjs, startTime]);

    useEffect(() => {
        if (limit >= currentLimit) {
            setLimit(limit + 1)
        }
    }, [limit])

    return {queue: returnQueue, newItemsToLoad};
}

/** Retrieves modTasks only for current instance */
export function useFilteredQueueInstance({filterOption, startTime=Date.now(), sortingOrder="oldest", instanceKey, structureKey}) {
    const [returnQueue, setReturnQueue] = useState([])
    const [newItemsToLoad, setNewItemsToLoad] = useState([]);

    const modObjs = useCollection('queue_filtered_' + filterOption.id);

    useEffect(() => {
        const filteredTasks = modObjs
            .filter(modTask => (modTask != null && modTask.type))
            .filter(item => sortingOrder==="oldest" ? item.time >= 0 : item.time <= startTime)
            .sort((a, b) => sortingOrder==="oldest" ? a.time - b.time : b.time - a.time);

        const newTasks = modObjs
            .filter(modTask => (modTask != null && modTask.type))
            .filter(item => sortingOrder==="oldest" ? item.time <= 0 : item.time >= startTime)
            .sort((a, b) => sortingOrder==="oldest" ? a.time - b.time : b.time - a.time);
        setReturnQueue(filteredTasks)
        setNewItemsToLoad(newTasks)

    },[modObjs, startTime, sortingOrder])

    return {queue: returnQueue, newItemsToLoad};
}

export function useFilteredQueueUser({filterOption, startTime=Date.now(), sortingOrder="oldest", instanceKey}) {
    const [returnQueue, setReturnQueue] = useState([])
    const [newItemsToLoad, setNewItemsToLoad] = useState([]);

    let modObjs = [];
    const queue = useUserQueue({ userId: instanceKey });
    const queueArray = Object.values(queue || {});

    if (filterOption === ModerationFilters.AwaitingDecision) {
        modObjs = queueArray.filter((v) => v.judgement === undefined);
    } else if (filterOption === ModerationFilters.HumanApproved) {
        modObjs = queueArray.filter((v) => v.judgement === "approve" && v.humanJudgement === true);
    } else if (filterOption === ModerationFilters.Rejected) {
        modObjs = queueArray.filter((v) => v.judgement === "reject");
    } else if (filterOption === ModerationFilters.AutomaticallyApproved) {
        modObjs = queueArray.filter((v) => v.judgement === "approve" && v.humanJudgement === false);
    } else if (filterOption === ModerationFilters.Warned) {
        modObjs = queueArray.filter((v) => v.receivedWarning === true);
    }

    useEffect(() => {
        const filteredTasks = modObjs
            .filter(modTask => (modTask != null && modTask.type))
            .filter(item => sortingOrder==="oldest" ? item.time >= 0 : item.time <= startTime)
            .sort((a, b) => sortingOrder==="oldest" ? a.time - b.time : b.time - a.time);

        const newTasks = modObjs
            .filter(modTask => (modTask != null && modTask.type))
            .filter(item => sortingOrder==="oldest" ? item.time <= 0 : item.time >= startTime)
            .sort((a, b) => sortingOrder==="oldest" ? a.time - b.time : b.time - a.time);
        setReturnQueue(filteredTasks)
        setNewItemsToLoad(newTasks)

    },[queue, startTime, sortingOrder])

    return {
        queue: returnQueue,
        newItemsToLoad,
    }
}