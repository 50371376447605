import { useIsAdmin } from "np-platform-client/component/admin";
import { useDatastore } from "np-platform-client/util/datastore";
import { useEffect, useState } from "react";

export function useModerationAuthorsByIds(ids = []) {
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();
    const [modAuthors, setModAuthors] = useState({}); 

    async function fetchModerationAuthors(idsToFetch) {
        const result = await datastore.callServerAsync('moderationZdf', 'getModItemAuthorsByIds', {authorIds: idsToFetch});
        // Functional update to prevent inconsistent states due to asynchronous handling
        setModAuthors(prevModAuthors => ({
            ...prevModAuthors,
            ...result
        }));
    }

    useEffect(() => {
        if (isAdmin) {
            const newIds = ids.filter(id => (modAuthors[id] == null));
            if(newIds.length > 0) {
                fetchModerationAuthors(newIds);
            }
        }
    }, [isAdmin, ids]);
    return modAuthors;
}

export function useModerationAuthorById(id) {
    return useModerationAuthorsByIds([id]);
}