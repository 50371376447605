import { LengthLimitFeature } from "np-platform-client/feature/LengthLimitFeature"
import { UpvoteFeature } from "np-platform-client/feature/UpvoteFeature"
import { VideoSyncFeature } from "./deprecated/VideoSyncFeature"
import { AgreeDisagreeFeature, CommentSliderFeature, CommentSliderHorizVisualizationFeature, CommentSliderPollPickerFeature, YesNoFeature, CommentSliderVertVisualizationFeature, CommentSliderSliderFeature, CommentSliderEnableVisualizationFeature } from "./question/CommentSliderFeature"
import { ModerateRepliesStrictlyFeature, PreModeratorFeature, ModerateWithGPTFeature, ModerateWithJigsawFeature, ModerationHelpFeature } from "./moderation/AIPreModeratorFeature"
import { ProfileArticleRecommendationsFeature } from "./zdf/ProfileArticleRecommendationsFeature"
import { ProfileConversationRecommendationsFeature } from "./zdf/ProfileConversationRecommendationsFeature"
import { ProfileInterestsFeature } from "./zdf/ProfileInterestsFeature"
import { VideoQuoteFeature } from "./zdf/VideoQuoteFeature"
import { ArticleTopicTaggingFeature } from "./zdf/ArticleTopicTagFeature"
import { ConvoMatchFeature } from "./zdf/ConvoMatchFeature"
import { ProfileAnswersFeature } from "./profile/ProfileAswersFeature"
import { TopicCreationFeature } from './cbcrc/admin/TopicCreationFeature'
import { CommentPreviewTeaserFeature, EditorialQuestionFeature, StandardTeaserFeature, ZeroStateGraphicTeaserFeature } from "./teaser/EditorialQuestionFeature"
import { NarwwhalReactionsFeature, NewPublicReactionsFeature, ReactionTypesFeature } from "./question/ReactionTypesFeature"
import { ReplyNotificationsFeature } from "np-platform-client/feature/ReplyNotifsFeature"
import { VideoVotingFeature, VideoVotingVideoOverlayFeature } from "./zdf/videovoting/VideoVotingArticleFeature"
import { VideoVotingOverviewFeature } from "./zdf/videovoting/VideoVotingOverviewFeature"
import { ProfileSettingsFeature } from "./zdf/ProfileSettingsFeature"
import { ProfileVideoVotingSettingFeature } from "./zdf/videovoting/ProfileVideoVotingSettingFeature.js"
import { AdminRemoveFeature } from "./moderation/AdminRemoveFeature"
import { CBCRCDemoFeature } from "../demo/cbcrc/componentdemo.js"
import { ZDFDemoFeature } from "../demo/zdf/componentdemozdf.js"
import { DemoConversationFeatures } from "../demo/conversationfeatures.js"
import { RepresentingPerspectivesCardFeature, RepresentingPerspectivesFeature, RepresentingPerspectivesSpeechBubbleFeature, RepresentingPerspectivesSubtleBubbleFeature } from "./question/RepresentingPerspectives"
import { ContextEntryPointsFeature } from "./question/ContextEntryPointsFeature"
import { ProfileTitledWriterFeature } from "./cbcrc/profile/profile"
import { TranslationFeature } from "../contrib/srg/translation/TranslationFeature"
import { ConversationHelperAddonFeatures, ConversationHelperFeature } from "./zdf/ConversationHelperFeature"
import { ConversationHelperSettingsFeatures } from "./zdf/ConversationHelperAdminFeature"
import { CHOOSEONE, COMPOSITE, SECTION, SUBSECTION } from "np-platform-client/feature"
import { TeaserDemoFeature } from "../demo/teaserdemo.js"
import { ProfileAnswersDemo } from "../demo/profileanswers-demo"
import { TopicTeaserFeature } from "./cbcrc/connect/TopicTeaserFeature"
import { PinCommentFeature } from "./question/PinCommentFeature"
import { ReportCommentFeature } from "./moderation/ReportCommentFeature"
import { AdminModLinkFeature } from "./moderation/AdminModLinkFeature"
import { DemoModerationFeature } from "../demo/moderation-demo"
import { HideRejectedFeature } from "./moderation/HideRejectedFeature"
import { HumanReviewsEverythingFeature } from "./moderation/HumanReviewsEverythingFeature"
import { DemoFallbacksFeature } from "../demo/fallbacks-demo"
import { ModerationDashDemoFeature } from "../demo/moderationdash-demo"
import { CloseConversationFeature } from "np-platform-client/feature/CloseConversationFeature"
import { OpenLinksInNewTabFeature } from "np-platform-client/feature/OpenLinksInNewTabFeature"
import { BlacklistFeature } from "./zdf/premoderation/BlacklistFeature"
import { BlacklistEditorFeature } from "./zdf/premoderation/BlacklistEditorFeature"
import { CommunityGuidelinesFeature } from "./question/CommunityGuidelinesFeature"
import { SingleResponseHelpFeature } from "./question/SingleResponseHelpFeature"
import { NextTopicPollFeature, TopicPollFeature, TopicPollTopPositionFeature, TopicPollBottomPositionFeature } from "./cbcrc/topic/TopicPollFeature"
import { TopicUserQuestionsFeature, TopicUserQuestionCallToActionFeature, TopicPopularQuestionsFeature } from "./cbcrc/topic/UserQuestionsFeature"
import { TopicRelatedConversationsFeature } from "./cbcrc/topic/RelatedConversationsFeature"
import { TopicRelatedContentFeature } from "./cbcrc/topic/RelatedContent"
import { TopicLiveStatusFeature } from "./cbcrc/topic/LiveStatusFeature"
import { TopicParticipantsFeature } from "./cbcrc/topic/TopicParticipants"
import { MockTopicFollowFeature } from "./cbcrc/topic/MockFollowFeature"
import { TopicPhotoTeaserFeature, TopicPhotoTeaserForTopicFeature } from "./teaser/TopicPhotoTeaser"
import { QuestionCardsFeature } from "./topic/QuestionCardsFeature"
import { EmbeddedFeature } from "./question/EmbeddedFeature"
import { ReadingListFeature } from "./topic/ReadingListFeature"
import { AskMeAnythingHeaderFeature } from "./cbcrc/topic/AskMeAnythingHeaderFeature"
import { TopicConfigDemoFeature, TopicConfigFeature } from "../demo/topic-config"
import { DemoFeature } from "np-platform-client/feature/comment-config"
import { DemoQuestionBasicsFeature } from "../demo/questionbasics-demo"
import { TopicDemoFeature } from "../demo/topic-demo"
import { PuppetSupportFeature } from "./admin/PuppetTestSupportFeature"
import { JumpToCommentFeature } from "./zdf/JumpToCommentFeature"
import { AutoApproveFeature } from "./zdf/premoderation/AutoApproveFeature"

export const newFeatures = {
    admin: [
        SECTION('Quick links', [
            AdminModLinkFeature
        ]),
        SECTION('Screens', [
            TopicCreationFeature,
            PuppetSupportFeature
        ])
    ],
    article: [
        COMPOSITE(EditorialQuestionFeature, [
            CHOOSEONE('Teaser Type', [
                StandardTeaserFeature,
                CommentPreviewTeaserFeature,
                ZeroStateGraphicTeaserFeature,
                TopicTeaserFeature,
                TopicPhotoTeaserFeature
            ])
        ]),
        // EditorialQuestionFeature,
        COMPOSITE(VideoVotingFeature, [
            VideoVotingVideoOverlayFeature
        ]),
    ],
    topic: [
        SECTION('Header', [
            TopicLiveStatusFeature,
            MockTopicFollowFeature,
            TopicParticipantsFeature,
        ]),
        SECTION('CBC/RC Content', [
            AskMeAnythingHeaderFeature,
            COMPOSITE(TopicPollFeature, [
                CHOOSEONE('Topic', [
                    TopicPollTopPositionFeature,
                    TopicPollBottomPositionFeature
                ])]),
            COMPOSITE(TopicUserQuestionsFeature, [
                TopicUserQuestionCallToActionFeature,
                TopicPopularQuestionsFeature,
                UpvoteFeature
            ]),
            TopicRelatedConversationsFeature,
            TopicRelatedContentFeature,
            NextTopicPollFeature,
        ]),
        SECTION('Other Content', [
            QuestionCardsFeature,
            ReadingListFeature,
        ]),
        SECTION('Moderation', [
            COMPOSITE(PreModeratorFeature, [
                AdminRemoveFeature,
                ReportCommentFeature
            ]),
        ]),
        SECTION('General', [
            TopicPhotoTeaserForTopicFeature,
            TopicConfigFeature
        ])
    ],
    simplecomments: [
        SECTION('PSI Extensions', [
            CommentSliderFeature,
            TranslationFeature,
            VideoSyncFeature,
            VideoQuoteFeature,
            ArticleTopicTaggingFeature,
            ConvoMatchFeature,
            CommunityGuidelinesFeature
        ]),
        SECTION('Moderation', [
            HideRejectedFeature,
            COMPOSITE(PreModeratorFeature, [
                ModerateWithGPTFeature,
                ModerateWithJigsawFeature,
                ModerateRepliesStrictlyFeature,
                BlacklistFeature,
                AutoApproveFeature
            ]),
            AdminRemoveFeature,
            ReportCommentFeature,
            HumanReviewsEverythingFeature,
            CloseConversationFeature
        ]),
    ],
    question: [
        SECTION('Conversation Type', [
            ContextEntryPointsFeature,
            COMPOSITE(CommentSliderFeature, [
                CHOOSEONE('Spectrum', [
                    YesNoFeature,
                    AgreeDisagreeFeature
                ]),
                CHOOSEONE('Composer', [
                    CommentSliderPollPickerFeature,
                    CommentSliderSliderFeature
                ]),
                SUBSECTION('Data Viz', [
                    COMPOSITE(CommentSliderEnableVisualizationFeature, [
                        CHOOSEONE(null, [
                            CommentSliderHorizVisualizationFeature,
                            CommentSliderVertVisualizationFeature
                        ]),
                    ]),
                ]),
            ]),
            COMPOSITE(RepresentingPerspectivesFeature, [
                CHOOSEONE('UI Variant', [
                    RepresentingPerspectivesCardFeature,
                    RepresentingPerspectivesSpeechBubbleFeature,
                    RepresentingPerspectivesSubtleBubbleFeature
                ]),    
            ]),
            COMPOSITE(ReactionTypesFeature, [
                CHOOSEONE('Reaction Set', [
                    NewPublicReactionsFeature,
                    NarwwhalReactionsFeature
                ])
            ]),
        ]),
        SECTION('Moderation', [
            HideRejectedFeature,
            COMPOSITE(PreModeratorFeature, [
                ModerateWithGPTFeature,
                ModerateWithJigsawFeature,
                ModerateRepliesStrictlyFeature,
                BlacklistFeature,
                ModerationHelpFeature,
                AutoApproveFeature
            ]),
            AdminRemoveFeature,
            ReportCommentFeature,
            HumanReviewsEverythingFeature,
            CloseConversationFeature,
        ]),
        SECTION('General', [
            LengthLimitFeature,
            COMPOSITE(ConversationHelperFeature, [
                ...ConversationHelperAddonFeatures,
                ...ConversationHelperSettingsFeatures,
            ]),
            VideoVotingOverviewFeature,
            ReplyNotificationsFeature,
            TranslationFeature,
            UpvoteFeature,
            PinCommentFeature,
            OpenLinksInNewTabFeature,
            CommunityGuidelinesFeature,
            SingleResponseHelpFeature,
            EmbeddedFeature,
            DemoFeature,
            JumpToCommentFeature
        ])
    ],
    profile: [
        SECTION('Activity', [
            ProfileAnswersFeature,
        ]),
        SECTION('ZDF', [
            ProfileInterestsFeature,
            ProfileConversationRecommendationsFeature,
            ProfileArticleRecommendationsFeature,
            COMPOSITE(ProfileSettingsFeature, [
                ProfileVideoVotingSettingFeature
            ])
        ]),
        SECTION('CBCRC', [
            ProfileTitledWriterFeature,
        ])
    ],
    componentdemo: [
        SECTION('PSI Components', [
            CBCRCDemoFeature,
            ZDFDemoFeature,
            DemoConversationFeatures,
            TeaserDemoFeature,
            ProfileAnswersDemo,
            DemoModerationFeature,
            DemoFallbacksFeature,
            ModerationDashDemoFeature,
            TopicConfigDemoFeature,
            DemoQuestionBasicsFeature,
            TopicDemoFeature
        ])
    ],
    moderationdash: [
        SECTION('Features', [
            BlacklistEditorFeature
        ])
    ]
}

export const newDefaultFeatures = {
    admin: {
        admin_link_mod: true,
        editorialtopic: true,
        puppettest: true
    },
    componentdemo: {
        demo_cbcrc: true,
        demo_zdf: true,
        demo_conversation: true,
        demo_teaser: true,
        demo_fallbacks: true,
        demo_moderationdash: true,
        demo_moderation: true,
        demo_questionbasics: true,
        demo_topic: true,
        demo_topic_config: true
    },
    article: {
        editorialquestion: true,
        zerostateteaser: true,
        videovotingArticle: false,
        videovotingArticleTextIntegration: false,
    },
    topic: {
        editorialtopic: true,
        topictype: true,
        poll: true,
        upvote: true,
        commentssort: true,
        premoderator: false,

        // All CBC modules on by default for now
        topicuserquestions: true,
        topicrelatedcontent: true,
        topicpoll: true,
        nexttopicpoll: true,
        topicuserquestions: true,
        topicfollow: true,
        topiclivestatus: true,
        topicparticipants: true,

        topicphototeaser_topic: true,
    },
    articlequestions: {
        upvotequestion: true,
        questionteaser: true
    },
    simplecomments: {
        premoderator: true,
        articletopictagging: false,
        convomatch: false,
        translationfeature: false,


        hide_rejected: true,
        premoderation: true,
        aimoderation: true,
        jigsawmoderation: true,
        strictreplymoderation: true,
        report_comment: true
    },
    question: {
        lengthlimit: false,
        upvote: false,
        reactiontypes: true,
        replynotifications: true,
        commentslider: false,
        translationfeature: false,
        community_guidelines: true,
        votingoverview: false,

        jumptocomment: true,

        // Radio button first features: TODO - auto-select these
        commentslider_yes: true,
        commentslider_poll_picker: true,
        commentslider_horiz_visualization: true,
        commentslider_enable_visualization: true,
        newpublicreactions: true,
        // representing_perspectives_card: true,
        representing_perspectives_speech_bubble: true,

        // Moderation 
        hide_rejected: true,
        aimoderation: true,
        jigsawmoderation: true,
        premoderation: true,
        aimoderation: true,
        report_comment: true,
        moderationhelp: true,
        autoapprovecomments: true,

        //Conversation helper Settings
        conversationhelper_timing_comments: true,
        conversationhelper_timing_comments_1: true,
        conversationhelper_personality_a: true,
        conversationhelper_scope_all_comments: true,
        conversationhelper_scope_threshold_1: true,
        conversationhelper_top_x_percent_3: true,
        conversationhelper_guidance_update_comment_interval_0: true,
    },
    profile: {
        profileanswers: true,
    }
}

export var newRoles = {
    Owner: {
    },
    Developer: {
        inherits: ['Editorial', 'Super-Moderator', 'Analyst'],
        can: ['features/toggle-experimental']
    },
    'Super-Moderator': {
        inherits: ['Moderator']
    },
    Moderator: {
        can: ['moderation/judge', 'moderation/view']
    },
    Editorial: {
        can: ['features/toggle-stable']
    },
    Analyst: {
        can: [
            'eventlog/view'
        ]
    }
}
