import { Flag, FlagFilled } from "@carbon/icons-react";
import { Pad } from "np-platform-client/component/basics";
import { CTAButton, Popup, SubtleButton } from "np-platform-client/component/button"
import { Checkbox } from "np-platform-client/component/form";
import { Modal } from "np-platform-client/component/modal";
import { UtilityText } from "np-platform-client/component/text";
import { useCollection, useDatastore, useObject, usePersonaKey, useServerCallResult, useSessionData } from "np-platform-client/util/datastore";
import { useEffect, useState } from "react";
import { View } from "react-native-web";
import { GUIDELINES } from "../question/CommunityGuidelinesFeature";

export const ReportCommentFeature = {
    name: 'Report Comment',
    key: 'report_comment',
    config: {
        commentRightActions: [ActionReportComment],
        pageTopWidgets: [GetMyReportDataComponent]
    }
}

export function ActionReportComment({commentKey}) {
    const reported = useSessionData(['reportedComments', commentKey]);
    const comment = useObject('comment', commentKey);
    const personaKey = usePersonaKey();

    if (!personaKey) return null;

    if (comment?.from == personaKey) return null;


    const reportPopup = ({onClose}) => <ReportPopup onClose={onClose} commentKey={commentKey}/>;
    const alreadyPopup = () => <UtilityText label='Your have already reported this' />;

    return <Popup testID='report' popupContent={reported ? alreadyPopup : reportPopup}>
        <SubtleButton icon={reported ? FlagFilled : Flag} ariaLabel='flag' />
    </Popup>
}

export function ReportPopup({onClose, commentKey}) {
    const [checkedRules, setcheckedRules] = useState([]);    
    const comment = useObject('comment', commentKey);
    const datastore = useDatastore();

    const guideline_names = [...Object.values(GUIDELINES), 'Other'];

    function onToggle(name) {
        if (checkedRules.includes(name)) {
            setcheckedRules(checkedRules.filter(rule => rule !== name));
        } else {
            setcheckedRules([...checkedRules, name]);
        }
    }

    async function onReport() {
        datastore.setSessionData(['reportedComments', commentKey], true);
        onClose();
        await datastore.callServerAsync('moderation', 'sendToReview', {
            type: 'comment', key: commentKey, source: 'report',
            text: comment.text,
            from:comment.from,
            violations: JSON.stringify(checkedRules)
        });
    }

    return <View style={{ maxWidth: 300}}>
        <UtilityText type='small' weight="medium" label='Which community guideline does this violate?' />
        <Pad size={12} />
        {guideline_names.map(name => 
            <Checkbox key={name} label={name} 
                value={checkedRules.includes(name)}
                onChange={() => onToggle(name)} 
            />
        )}
        <Pad size={12} />
        <CTAButton wide label='Report' disabled={checkedRules.length === 0} 
            onPress={onReport} />
    </View>
}

// Retrieve your reported comments initially to prevent reporting comments twice 
function GetMyReportDataComponent () {
    const personaKey = usePersonaKey()
    const datastore = useDatastore();
    const reports = useServerCallResult("moderationZdf", "getReportsGivenForUser", {key: personaKey})
    useEffect(()=>{
        if(!reports || !personaKey) return
        // prepopulate session
        Object.values(reports).forEach((report)=>{
            datastore.setSessionData(['reportedComments', report.objectKey], true);
        });
    }, [reports])
}
