import { DemoSection } from "np-platform-client/component/demo";
import { ModDashboardThreadDetails, ModDashboardThreadsOverviewList, ModDashboardThreadCard, ModDashboardThreadsOverview } from "../../../component/zdf/moddashboard/moddashboardthreads";
import { defaultPersonaList } from "np-platform-client/util/testpersonas";
import { View } from "react-native-web";
import { modDashboardDemoData } from "./moddashboard.data";
import { Datastore } from "np-platform-client/util/datastore";
import { EmbeddedInstance } from "np-platform-client/util/instance";


export function ModDashboardThreadsDemo() {

    const demoThread = modDashboardDemoData.modulePublicQuestion.thread1
    const threadList = Object.values(modDashboardDemoData.modulePublicQuestion)

    const author = defaultPersonaList[0]
    const authors = modDashboardDemoData.demoAuthors

    const modulePublic = {
        question: {
            ...modDashboardDemoData.modulePublicQuestion
        },
        moderation: {
            queue: {
                ...modDashboardDemoData.modTaskQueues.thread1
            },
            thread_queue_count: {
                ...modDashboardDemoData.threadQueueCounts
            }
        }
    }

    const embeddedInstanceData = {
        question: {
            [demoThread.key] : modDashboardDemoData.instanceData.thread1
        }
    }

    return <Datastore modulePublic={modulePublic} embeddedInstanceData={embeddedInstanceData} serverCall={modDashboardDemoData.serverCalls.default}>
        <DemoSection label={"Thread Card"}>
            <ModDashboardThreadCard
                thread={demoThread}
                mainTag={"Active"}
                author={author}
            />
        </DemoSection>
        <DemoSection label={"Thread Card with secondary tags"}>
            <ModDashboardThreadCard
                thread={demoThread}
                mainTag={"Active"}
                secondaryTags={["Conversation Helper", "Video Voting"]}
                author={author}
            />
        </DemoSection>
        <DemoSection label={"Thread Card of a heated thread"}>
            <ModDashboardThreadCard
                thread={modDashboardDemoData.modulePublicQuestion.thread1}
                mainTag={"Active"}
                secondaryTags={["Conversation Helper", "Video Voting"]}
                author={author}
            />
        </DemoSection>
        <DemoSection label={"Threads Overview"}>
            {/* TODO: How can we add the authors to this example? */}
            <ModDashboardThreadsOverview/>
        </DemoSection>
        <DemoSection label={"Threads Overview List"}>
            <ModDashboardThreadsOverviewList threads={threadList} authors={authors} />
        </DemoSection>
        <DemoSection label={"Thread Details"}>
            <View>
                <EmbeddedInstance structureKey={"question"} instanceKey={demoThread.key} screenKey={"moderationDetails"}>
                    <ModDashboardThreadDetails/>
                </EmbeddedInstance>
            </View>
        </DemoSection>
    </Datastore>
}