import { Banner } from "np-platform-client/component/banner";
import { Pad, PadBox, HorizBox } from "np-platform-client/component/basics";
import { CTAButton, SubtleButton } from "np-platform-client/component/button";
import { colorLightBlueBackground, colorPink, colorBlackHover, colorGreyBorder, colorTextGrey } from "np-platform-client/component/color";
import { RichText } from "np-platform-client/component/richtext";
import { Heading, TextField, UtilityText } from "np-platform-client/component/text";
import { useDatastore, useSessionData } from "np-platform-client/util/datastore";
import { useState } from "react";
import { Modal } from "np-platform-client/component/modal";
import { goBack } from "np-platform-client/util/navigate";
import { logEventAsync, useLogEvent } from "np-platform-client/util/eventlog";
import { GUIDELINES, GuidelinesModal } from "../question/CommunityGuidelinesFeature";
import { View, StyleSheet } from "react-native";
import { Pin, Send } from "@carbon/icons-react";
import { formatDate } from "np-platform-client/component/date";
import React from "react";
import { getWillHelpBeSeenAsync, HelpBubble } from "np-platform-client/component/help";
import { sleepMillisAsync } from "np-platform-client/util/util";

export const PreModeratorFeature = {
    name: 'Pre Moderation',
    key: 'premoderation',
    config: {
        commentPostBlockers: [isPostButtonDisabledAsync],
        commentEditBottomWidgets: [ModerationReport],
        commentPostCheckers: [checkPostAsync],
        topBarHelpBubbles: [ModerationInProgessHelpBubble]
    },
    defaultConfig: {
        commentModerators: [],
        useOnlyGptModeration: false
    }
}

export const ModerationHelpFeature = {
    name: 'Moderation Help Bubble',
    key: 'moderationhelp',
    config: {
        topBarHelpBubbles: [ModerationInProgessHelpBubble],
        commentPostCheckers: [waitForHelpBubbleAsync]
    }
}

export const ModerateWithGPTFeature = {
    name: 'GPT',
    key: 'aimoderation',
    config: {
        commentModerators: [moderateWithGPT]
    }
}

export const ModerateWithJigsawFeature = {
    name: 'Jigsaw',
    key: 'jigsawmoderation',
    config: {
        commentModerators: [moderateWithJigsaw]
    }
}

async function moderateWithGPT({datastore, comment}) {
    const moderationResult = await datastore.callServerAsync(
        'moderation', 'checkCommentWithGpt', {text: comment.text});
    if (moderationResult.violates) {
        const violations = moderationResult.rulesBroken?.map(getRuleDescription) ?? [];
        return {
            violates: moderationResult.violates, violations, source: 'ai',
            metadata: {rulesBroken: JSON.stringify(moderationResult.rulesBroken ?? [])}
        };
    } else {
        return {violates: false}
    }
}

async function moderateWithJigsaw({datastore, comment}) {
    const moderationResult = await datastore.callServerAsync(
        'moderation', 'checkCommentWithJigsaw', {text: comment.text});
    if (moderationResult.violates) {
        return {
            violates: true, source: 'jigsaw', 
            violations: [GUIDELINES.NO_UNCIVIL],
            metadata: {toxicity: moderationResult.toxicity}
        };
    } else {
        return {violates: false}
    }
}

export const ModerateRepliesStrictlyFeature = {
    name: 'Strict Reply Moderation',
    key: 'strictreplymoderation',
    config: {
        commentModerators: [moderateRepliesStrictly]
    }
}

export function getRuleDescription(rule) {
    switch (rule) {
        case 0:
        case 1:
        case 6:
        case 10:
            return GUIDELINES.NO_UNCIVIL;
        case 2:
            return GUIDELINES.NO_HARM;
        case 3:
        case 4:
            return GUIDELINES.NO_LABELS;
        case 5:
            return GUIDELINES.NO_INSULTS;
        case 7:
            return GUIDELINES.BACKUP_CLAIMS;
        case 8:
            return GUIDELINES.NO_SPAM;
        case 9:
            return GUIDELINES.RESPECT_PRIVACY;
        default:
            return GUIDELINES.NO_UNCIVIL;
    }
}

const strictReplyDescription = 'Avoid picking fights - If you disagree with someone, speak as if you are open to them being right, and invite them to explain their position better to you.'; 

async function moderateRepliesStrictly({datastore, comment}) {
    if (!comment.replyTo) {
        return {violates: false}
    }
    const parentComment = await datastore.getObject('comment', comment.replyTo);
    const moderationResult = await datastore.callServerAsync('moderation', 'moderateReplyStrictly', {
        parentText: parentComment.text, text: comment.text, 
        commentKey: comment.key || null, inReview: comment.inReview
    });
    if (!moderationResult.allow) {
        return {violates: true, source: 'ai-reply-moderaton',
            violations: [strictReplyDescription],
        }
    } else {
        return {violates: false}
    }
}


async function checkPostAsync({datastore, comment}) {
    const {commentModerators} = datastore.getConfig();

    if (!comment.text?.trim()) {
        return {allow: true, commentProps: {violates: false, inReview: false}};
    }

    var violation = null;
    var violations = [];

    for (const moderator of commentModerators) {
        const moderationResult = await moderator({datastore, comment});
        if (moderationResult.violates) {
            violations = [...violations, ...moderationResult.violations];
            violation = moderationResult;
            logEventAsync(datastore, 'moderation-block', {text: comment.text});
            break;
        }
    }
    violations = [...new Set(violations)];
    const violates = violations.length > 0;
    const violatingTextChanged = comment.violatingText && (comment.text !== comment.violatingText);

    if (comment.inReview && (!violates || violatingTextChanged)) {
        await datastore.callServerAsync('moderation', 'removeCommentFromReviewQueue', {commentKey: comment.key});
        await datastore.updateObject("comment", comment.key, { ...comment, inReview: false });
    }

    return {
        allow: !violates, commentProps: {
            violates, inReview: false, violations,
            violatingText: violates ? comment.text : null,
        }
    };
}

function ModerationInProgessHelpBubble() {
    const moderationInProgress = useSessionData('moderationInProgress');
    if (!moderationInProgress) { return null; }
    return <HelpBubble right label='Your response is being reviewed by AI to ensure it follows our Community Guidelines' 
        condition={moderationInProgress} helpKey='moderationInProgress'
    />
}

async function waitForHelpBubbleAsync({datastore, comment}) {
    const helpSeen = await getWillHelpBeSeenAsync({datastore, helpKey: 'moderationInProgress'});
    if (comment.replyTo && !helpSeen) return {allow: true};
    datastore.setSessionData('moderationInProgress', true);
    await sleepMillisAsync(4000);
    datastore.setSessionData('moderationInProgress', false);
    return {allow: true}; 
}




function isPostButtonDisabledAsync({datastore, comment}) {
    return comment.violates && comment.violatingText == comment.text;
}

export function ModerationReport({comment, onCancel}) {
    const s = moderationReportStyles;
    const [showModerationModal, setShowModerationModal] = useState(false);
    const [showCommunityGuidelines, setShowCommunityGuidelines] = useState(false);
    if (!comment.violates) {
        return null;
    } else {
        return <View style={s.container}>
            <PadBox horiz={20} vert={16}>
                <Heading label='⚠️ Take another look'/>
                <Pad size={16} />
                <UtilityText label='Your post may have violated the following Community Guidelines:'/>
                <Pad size={16} />
                {comment.violations?.map((violation, i) => <BulletText key={i} label={violation} />)}
                <Pad size={20} />
                <View style={s.line} />
                <Pad size={20} />
                <UtilityText weight='medium' label='Need help?' />
                <Pad size={16} />
                <SubtleButton type='tiny' icon={Pin} color={colorBlackHover} label='Community Guidelines' onPress={() => setShowCommunityGuidelines(true)}/>
                <Pad size={12} />
                {comment.inReview 
                    ? <View style={{flexDirection: 'row'}}>
                        {React.createElement(Send, { style: { color: colorBlackHover } })}
                        <PadBox right={4}/>
                        <UtilityText color={colorBlackHover} type='tiny' label='Sent to moderator: {time}' formatParams={{time: formatDate(comment.time)}}/>
                    </View>
                    : <SubtleButton type='tiny' icon={Send} color={colorBlackHover} label='Send to moderator' onPress={() => setShowModerationModal(true)}/>
                }
            </PadBox>
            {showModerationModal && <ModerationModal comment={comment} onCancel={onCancel} onClose={() => setShowModerationModal(false)} />}
            {showCommunityGuidelines && <GuidelinesModal onClose={() => setShowCommunityGuidelines(false)}/>}
        </View>
    }
}
const moderationReportStyles = StyleSheet.create({
    container: {
        backgroundColor: colorPink,
    },
    line: {
        height: 1, 
        backgroundColor: colorGreyBorder,
        width: '100%',
    },
});

function BulletText({label, text}) {
    return <HorizBox>
        <UtilityText text='•' />
        <Pad size={10}/>
        <UtilityText label={label} text={text} />
    </HorizBox>
}

// TODO: Bring back link to transparency dashboard, once we have cross-partner alignment
// and it works better

export function ModerationModal({comment, onClose, onCancel}) {
    const [inProgress, setInProgress] = useState(false);
    const [sent, setSent] = useState(false);
    const [appealText, setAppealText] = useState('');
    const datastore = useDatastore();
    const siloKey = datastore.getSiloKey();
    useLogEvent('moderation-modal', {text: comment.text});
    async function onReport() {
        setInProgress(true);
        const commentInReview = await datastore.callServerAsync('moderation', 'sendToReview', {
            type: 'comment', setInReview: true, source: 'premoderation',
            text: comment.text, key: comment.key, from: comment.from,
            object: comment,
            violations: JSON.stringify(comment.violations), appealText
        });
        
        // Set the comment clientside to properly trigger derived views
        if(commentInReview.key) {
            datastore.setObject("comment", commentInReview.key, commentInReview)
        }
        setInProgress(false);
        setSent(true);
        logEventAsync(datastore, 'moderation-appeal', {text: appealText});
    }

    function onDone() {
        onClose();
        if (onCancel) {
            onCancel();
        } else {
            goBack();
        }
    }

    const dashboardUrl = 'https://np-psi-dev.web.app/' + siloKey + '/moderationdash/dash';

    if (sent) {
        return <Modal onClose={onDone}>
            <PadBox horiz={20} vert={20}>
                <Heading type="large" weight="medium" label='Thank you!'/>
                <Pad size={8} />
                <UtilityText label='Your response has been sent to a moderator for review.' color={colorTextGrey}/>
                {/* To be shown once Issue 161 is completed https://github.com/wearenewpublic/psi-product/issues/161
                <Pad size={32} />
                    <Banner color={colorLightBlueBackground}>
                        <Heading label='📫 What happens next'/>
                        <Pad size={16} />
                        <BulletText label='You will receive an email notification when your post is approved or rejected by moderators.'/>
                    </Banner>
                <Pad /> */}
            </PadBox>  
        </Modal>
    } else {
        return <Modal onClose={onClose} buttonRow={
            <CTAButton disabled={inProgress || !appealText} wide type='primary' label={inProgress ? 'Sending...' : 'Send to moderator'} onPress={onReport}/>
        }>
            <PadBox horiz={20} vert={20}>
                <Heading type="large" weight="medium" label='Send to moderator'/>
                <Pad />
                <Banner color={colorLightBlueBackground}>
                    <Heading label='✨ We use AI for moderation'/>
                    <Pad size={32} />
                    <RichText label='AI helps us to manage moderation, but sometimes it triggers incorrectly. If you are unable to get our AI to accept a respectfully expressed opinion, please send it to our moderators for review.'/>
                </Banner>
                <Pad size={32} />
                <UtilityText type='small' weight="medium" label='Message to moderator*' />
                <Pad size={12} />
                <TextField testID='appeal' big value={appealText} onChange={setAppealText} placeholder='Message to moderator...'/>
                <Pad size={12} />
                <UtilityText type='small' label='Please explain why we should publish your post.' color={colorTextGrey} />
            </PadBox>  
        </Modal>
    }
}
