import { ChevronLeft, FaceDissatisfied, FaceSatisfied, User } from "@carbon/icons-react";
import { HorizBox, Pad, PadBox, Separator } from "np-platform-client/component/basics";
import { CTAButton, SubtleButton, TextButton } from "np-platform-client/component/button";
import { colorBlack, colorBlackHover, colorGreen, colorRed, colorTextGrey, colorWhite } from "np-platform-client/component/color";
import { Checkbox } from "np-platform-client/component/form";
import { Heading, UtilityText } from "np-platform-client/component/text";
import { useDatastore, usePersonaKey, usePersonaObject } from "np-platform-client/util/datastore";
import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { View } from "react-native-web";
import { GUIDELINES } from "../../../feature/question/CommunityGuidelinesFeature";
import { ToolTip } from "../tooltip";
import { Banner } from "np-platform-client/component/banner";
import { updateModerationSessionData } from "../../../structure/zdf/moddashboard";
import { useParentDatastore } from "../parentdatastore";
import { useServerCallResult } from "np-platform-client/util/servercall";
import { Modal } from "np-platform-client/component/modal";


async function setJudgementAsync({ judgement, datastore, modTask, reasoning }) {
    await datastore.callServerAsync('moderation', 'setJudgement', {
        key: modTask.key, judgement, reasoning: reasoning || modTask.reasoning
    });
}

export function JudgementCard({ modTask, modAuthor, taskWatchState }) {
    const datastore = useDatastore();

    const amIViewing = taskWatchState?.amIViewing
    const [pageIndex, setPageIndex] = useState(0);

    const s = StyleSheet.create({
        elevatedCard: {
            backgroundColor: !taskWatchState || amIViewing ? colorWhite : "#F7F7F7",
            width: "fit-content",
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
            borderRadius: 8,
            padding: 16,
            shadowColor: "#0000001A",
            shadowOffset: { width: 0, height: 4 },
            shadowRadius: 20,
            borderColor: colorBlack,
            borderWidth: 1,
            gap: 12
        }
    });

    async function onApprove() {
        // Reset warning for this comment if there is one to avoid giving multiple warnings per comment
        if (modTask.receivedWarning) {
            const userId = modAuthor.authorKey;

            await datastore.callServerAsync('moderationZdf', 'warnUser', {
                key: userId, commentKey: modTask.key, receivedWarning: false
            });
        }

        await setJudgementAsync({ judgement: "approve", datastore, modTask, reasoning: "" });
        datastore.setSessionData(["preliminaryJudgement", null]);
    }

    return (
        <ToolTip label={"Only one moderator at a time can work on a comment"} disabled={!taskWatchState || amIViewing}>
            <View style={s.elevatedCard}>
            {pageIndex === 0 && (
                <>
                    {(modTask.judgement === "reject" || !modTask.judgement) && (
                        <CTAButton
                            icon={<FaceSatisfied style={{ fill: colorGreen }} />}
                            label="Approve"
                            type="secondary"
                            onPress={() => {
                                onApprove();
                            }}
                            disabled={taskWatchState && !amIViewing}
                            wide
                        />
                    )}
                    {(modTask.judgement === "approve" || !modTask.judgement) && (
                        <CTAButton
                            icon={<FaceDissatisfied style={{ fill: colorRed }} />}
                            label="Reject"
                            type="secondary"
                            onPress={() => setPageIndex(1)}
                            disabled={taskWatchState && !amIViewing}
                            wide
                        />
                    )}
                </>
            )}
            {pageIndex === 1 && (
                <RejectionScreen
                    onBack={() => setPageIndex(0)}
                    onNext={() => setPageIndex(2)}
                    modTask={modTask}
                    modAuthor={modAuthor}
                />
            )}
            {pageIndex === 2 && (
                <WarnAndBlockScreen
                    onBack={() => setPageIndex(1)}
                    onNext={() => setPageIndex(0)}
                    modTask={modTask}
                    modAuthor={modAuthor}
                />
            )}
            </View>
        </ToolTip>
    );
}

function RejectionScreen({ onBack, onNext, modTask, modAuthor }) {
    const datastore = useDatastore();

    const guidelineNames = [...Object.values(GUIDELINES), 'Other'];

    const defaultCheckboxStates = {};
    guidelineNames.forEach((_, index) => defaultCheckboxStates[index] = false);
    const [selected,setSelected] = useState([])
    const [reasoning, setReasoning] = useState([]);
    const [checkboxStates, setCheckboxStates] = useState(defaultCheckboxStates);

    const s = StyleSheet.create({
        guidelineColumn: {
            margin: 4,
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "space-evenly"
        }
    });

    function onChangeSelection({ isSelected, index, guidelineName }) {
        if (isSelected && !selected.includes(index)) {
            setReasoning([...reasoning, guidelineName]);
            setSelected([...selected, index]);
        } else if (!isSelected && selected.includes(index)) {
            const cleanedUpSelection = selected.filter((element) => element != index);
            const cleanedUpReasoning = reasoning.filter((element) => element != guidelineName);
            setSelected(cleanedUpSelection);
            setReasoning(cleanedUpReasoning);
        }
        checkboxStates[index] = isSelected;
        setCheckboxStates(checkboxStates);
    }

    async function onReject() {
        // Check if user is already blocked. If they are, skip the warn & block dialog and finish rejecting the comment immediately.
        // If not, set judgement and reasoning but don't update the queue yet. Otherwise, the comment will disappear from the current filter category before the warn/block dialog can be shown.

        const isBlocked = await datastore.callServerAsync("moderationZdf", "getIsUserBlocked", { key: modAuthor.authorKey });

        const preliminaryJudgement = {
            judgement: "reject",
            datastore,
            modTask,
            reasoning: JSON.stringify(reasoning),
        };

        if (isBlocked) {
            await setJudgementAsync(preliminaryJudgement);
            datastore.setSessionData(["preliminaryJudgement", null]);
            onBack();
        }
        else {
            // Save preliminary judgement in session data. Setting the final judgement here would move the mod task to another filter before moderators reach the warn & block screen.
            datastore.setSessionData(["preliminaryJudgement"], preliminaryJudgement);
            onNext();
        }
    }

    return (
        <View style={s.guidelineColumn}>
            <SubtleButton icon={ChevronLeft} label={"Back to selection"} onPress={onBack} />
            <Pad size={12} />
            <UtilityText label={"Which community guideline does this comment violate?"} strong />
            <Pad size={12} />
            {guidelineNames.map((guidelineName, index) => (
                <Checkbox
                    label={guidelineName}
                    key={index}
                    value={checkboxStates[index]}
                    onChange={(isSelected) => {
                        onChangeSelection({ isSelected, index, guidelineName });
                    }}
                />
            ))}
            <Pad size={12} />
            <CTAButton
                wide
                label={"Reject"}
                disabled={reasoning.length === 0}
                onPress={async () => {
                    onReject();
                }}
            />
        </View>
    );
}

function WarnAndBlockScreen({ onBack, onNext, modTask, modAuthor }) {
    const datastore = useDatastore();
    const parentDatastore = useParentDatastore();
    const userId = modAuthor.authorKey;
    const currentModeratorKey = usePersonaKey();
    const currentModeratorPersona = usePersonaObject(currentModeratorKey);

    const [showBlockConfirmationModal, setShowBlockConfirmationModal] = useState(false);

    let warningCount = useServerCallResult("moderationZdf", "getUserWarningCount", { key: userId });
    if (!warningCount) {
        warningCount = 0;
    }

    async function onWarn() {
        await datastore.callServerAsync('moderationZdf', 'warnUser', {
            key: userId, commentKey: modTask.key, receivedWarning: true
        });

        rejectCommentAsync();
        onNext();
    }
    
    // TODO: What happens to comments in the mod queue if a user is already blocked? They probably don't require human attention anymore. Should we give an unblock option? Or automatically reject all comments?
    async function onBlock() {
        const isBlocked = await datastore.callServerAsync("moderationZdf", "getIsUserBlocked", { key: userId });
        if (!isBlocked) {
            await datastore.callServerAsync("moderationZdf", "setIsUserBlocked", {
                key: userId,
                isBlocked: true,
                blockedByKey: currentModeratorKey,
                blockedByName: currentModeratorPersona.name,
            });
        }

        rejectCommentAsync();
        setShowBlockConfirmationModal(false);
        onNext();
    }

    async function onContinueWithoutWarnOrBlock() {
        await rejectCommentAsync();
        onNext();
    }

    async function rejectCommentAsync() {
        const judgement = datastore.getSessionData(['preliminaryJudgement']);
        if (!judgement) return;

        // Update mod task and move it to the rejected queue
        await setJudgementAsync(judgement);
        datastore.setSessionData(["preliminaryJudgement", null]);
    }

    function onProfile() {
        updateModerationSessionData({
            datastore: parentDatastore,
            sessionData: {
                userId: userId,
            },
        });
    }

    return (
        <View>
            <SubtleButton icon={ChevronLeft} label={"Back to selection"} onPress={onBack} />
            <Pad size={12} />
            <UtilityText label={"Want to do anything else?"} strong />
            <Pad size={12} />
            <CTAButton
                wide
                type="secondary"
                label={"Warn user"}
                onPress={async () => {
                    onWarn();
                }}
            />
            {warningCount >= 1 && (
                <>
                    <Pad size={12} />
                    <Banner>
                        <UtilityText
                            label={
                                warningCount === 1
                                    ? "User has already been warned " + warningCount + " time"
                                    : "User has already been warned " + warningCount + " times"
                            }
                            color={colorBlackHover}
                        />
                        <Pad size={16} />
                        <Separator />
                        <Pad size={16} />
                        <HorizBox>
                            <User />
                            <Pad size={4} />
                            <TextButton
                                type="small"
                                color={colorBlackHover}
                                strong
                                label="Show user profile"
                                onPress={onProfile}
                            />
                        </HorizBox>
                    </Banner>
                </>
            )}
            <Pad size={12} />
            {warningCount >= 2 && (
                <>
                    <CTAButton
                        wide
                        type="delete"
                        label={"Block user"}
                        onPress={() => setShowBlockConfirmationModal(true)}
                    />
                    {showBlockConfirmationModal && (
                        <BlockConfirmationModal
                            onBlock={onBlock}
                            onClose={() => setShowBlockConfirmationModal(false)}
                        />
                    )}
                    <Pad size={12} />
                </>
            )}
            <CTAButton
                wide
                type="primary"
                label={"No thanks"}
                onPress={async () => {
                    onContinueWithoutWarnOrBlock();
                }}
            />
        </View>
    );
}

export function BlockConfirmationModal({ onBlock, onClose }) {
    return (
        <Modal onClose={onClose}>
            <PadBox vert={40} horiz={20}>
                <Heading label="Block this user?" level={1} />
                <Pad size={8} />
                <UtilityText
                    label="Do you really want to block this user? You can unblock the user via the profile page."
                    color={colorTextGrey}
                />
                <Pad size={32} />
                <CTAButton
                    wide
                    type="delete"
                    label={"Block user"}
                    onPress={async () => {
                        onBlock();
                    }}
                />
            </PadBox>
        </Modal>
    );
}

export function UnblockConfirmationModal({ onUnblock, onClose }) {
    return (
        <Modal onClose={onClose}>
            <PadBox vert={40} horiz={20}>
                <Heading label="Unblock this user?" level={1} />
                <Pad size={8} />
                <UtilityText label="Do you really want to unblock this user?" color={colorTextGrey} />
                <Pad size={32} />
                <CTAButton
                    wide
                    type="delete"
                    label={"Unblock user"}
                    onPress={async () => {
                        onUnblock();
                    }}
                />
            </PadBox>
        </Modal>
    );
}