import { DemoSection } from "np-platform-client/system/demo";
import { ModDashboardUserProfile } from "../../../component/zdf/moddashboard/moddashboarduserprofile";
import { defaultPersonaList } from "np-platform-client/util/testpersonas";

const demoAuthor = {
    ...defaultPersonaList[1],
    firstLogin: 1000000000000
}


export function ModDashboardUserProfileDemo() {
    return <DemoSection label={"Moderation Dashboard Profile Demo"}>
        <ModDashboardUserProfile user={demoAuthor} />
    </DemoSection>
}